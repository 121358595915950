import React from "react"
import { Col, Container, Row } from "react-grid-system"
import styles from './styles.module.scss'

const Comments = () => {
  return <div className={styles.wrapper}>
    <Container className={styles.container}>
      <Row>
        <Col md={3} className={styles.col_wrapper}>
          <h4 className={styles.header}>
            5,000+
          </h4>
          <p className={styles.text}>
            successful cases completed
          </p>
        </Col>
        <Col md={3} className={styles.col_wrapper}>
          <h4 className={styles.header}>
            150+
          </h4>
          <p className={styles.text}>
            international customers served
          </p>
        </Col>
        <Col md={3} className={styles.col_wrapper}>
          <h4 className={styles.header}>
            20+
          </h4>
          <p className={styles.text}>
            in-house experts with diverse backgrounds and skillsets
          </p>
        </Col>
        <Col md={3} className={styles.col_wrapper}>
          <h4 className={styles.header}>
            45+
          </h4>
          <p className={styles.text}>
            countries covered with access to local stakeholders
          </p>

        </Col>
      </Row>
    </Container>
  </div>
}

export default Comments


const addresses = {
  col1: [
    {
      country: 'United Kingdom',
      line1: '207 Regent Street,',
      line2: 'London W1B 3HH'
    },
    {
      country: 'Latvia',
      line1: 'Audēju iela 15,',
      line2: 'Centra rajons, Rīga, LV-1050'
    },
    {
      country: 'Türkiye',
      line1: 'İçerenköy, Umut Sok,',
      line2: '10-12, İstanbul'
    },
    {
      country: 'Kazakhstan',
      line1: '16, Turkistan, Office 56',
      line2: 'Esil district, Astana'
    },
    {
      country: 'Georgia',
      line1: '24 University Street,',
      line2: 'Tbilisi 0160'
    },
    {
      country: 'United Arab Emirates',
      line1: 'M Floor, Business Center,',
      line2: 'Meydan Hotel, Nad Al Sheba, Dubai'
    }
  ],
}

export default addresses

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles-module__space--VMF3t {
  height: 106px;
}
@media screen and (max-width: 767px) {
  .styles-module__space--VMF3t {
    height: 99px;
  }
}`, "",{"version":3,"sources":["webpack://./src/shared/components/HeaderSpace/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAAE;EAFF;IAGI,YAAA;EAGF;AACF","sourcesContent":[".space {\n  height: 106px;\n  @media screen and (max-width: 767px) {\n    height: 99px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"space": `styles-module__space--VMF3t`
};
export default ___CSS_LOADER_EXPORT___;

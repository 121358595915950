import React from "react"
import { Col, Container, Row } from "react-grid-system"
import styles from './styles.module.scss'
const Bubble = ({ quote, author, title }: {
  quote: string
  author: string
  title: string
}) => {
  return <div className={styles.section}>
    <Container style={{
      maxWidth: 1200
    }}>
      <Row className={styles.wrapper}>
        <Col lg={6} style={{
          textAlign: 'right'
        }}>
          <img className={styles.left_mage} src={require('../../../../../public/images/owner.png')} />
          <img className={styles.quotes} src={require('../../../../../public/images/quotes-new.svg')} />
        </Col>
        <Col lg={6}>
          <div className={styles.container}>
            <p>{quote}</p>
          </div>
          <div className={styles.title_wrapper}>
            <h4>{author}</h4>
            <p>{title}</p>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
}

export default Bubble

import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { pages } from "../data/page_carousel_data";

const useScrollToPage = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [isBottomOfThePage, setIsBottomOfThePage] = useState(false)
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (scrollTop + windowHeight >= documentHeight - 300) {
      setIsBottomOfThePage(true)

      return
    }
    setIsBottomOfThePage(false)
  };
  const location = useLocation();

  const goToPage = useCallback(() => {
    const nextPageIndex = pages.findIndex(page => page.indexOf(location.pathname) == 0) + 1

    const nextPageId = pages[nextPageIndex]

    navigate(nextPageId, {
      replace: false,
      preventScrollReset: true
    })
  }, [location])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return {
    isBottomOfThePage,
    goToPage
  }
}

export default useScrollToPage

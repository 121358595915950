import React from "react"

const NotFound = () => {
  return <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    flexDirection: 'column'
  }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
      <circle cx="50" cy="50" r="40" fill="#14346f" />
      <line x1="30" y1="30" x2="70" y2="70" stroke="#fff" stroke-width="8" />
      <line x1="70" y1="30" x2="30" y2="70" stroke="#fff" stroke-width="8" />
    </svg>

    <h1>Page Not Found. We'll add it soon...</h1>
  </div >
}

export default NotFound

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles-module__visible_xs--F0FFT {
  display: none;
}

.styles-module__hidden_xs--uXCkn {
  display: block;
}

@media screen and (max-width: 767px) {
  .styles-module__header--KIiLe {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .styles-module__header--KIiLe p {
    font-size: 20px !important;
  }
  .styles-module__title_comment--Glabi {
    padding-bottom: 0 !important;
  }
  .styles-module__title_comment--Glabi p {
    font-size: 24px !important;
  }
  .styles-module__comment--OWCo6 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .styles-module__visible_xs--F0FFT {
    display: block !important;
  }
  .styles-module__hidden_xs--uXCkn {
    display: none !important;
  }
  b {
    margin-top: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Contact/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE;IACE,4BAAA;IACA,+BAAA;EACF;EAAE;IACE,0BAAA;EAEJ;EACA;IACE,4BAAA;EACF;EAAE;IACE,0BAAA;EAEJ;EAEA;IACE,4BAAA;IACA,+BAAA;EAAF;EAIA;IACE,yBAAA;EAFF;EAKA;IACE,wBAAA;EAHF;EAMA;IACE,gBAAA;EAJF;AACF","sourcesContent":[".visible_xs {\n  display: none;\n}\n\n.hidden_xs {\n  display: block;\n}\n\n@media screen and (max-width: 767px) {\n  .header {\n    padding-top: 48px !important;\n    padding-bottom: 48px !important;\n    p {\n      font-size: 20px !important;\n    }\n  }\n  .title_comment {\n    padding-bottom: 0 !important;\n    p{\n      font-size: 24px !important;\n    }\n  }\n\n  .comment {\n    padding-top: 48px !important;\n    padding-bottom: 48px !important;\n\n  }\n\n  .visible_xs {\n    display: block !important;\n  }\n\n  .hidden_xs {\n    display: none !important;\n  }\n\n  b {\n    margin-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"visible_xs": `styles-module__visible_xs--F0FFT`,
	"hidden_xs": `styles-module__hidden_xs--uXCkn`,
	"header": `styles-module__header--KIiLe`,
	"title_comment": `styles-module__title_comment--Glabi`,
	"comment": `styles-module__comment--OWCo6`
};
export default ___CSS_LOADER_EXPORT___;

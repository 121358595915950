import React from "react"
import { Col, Container, Row } from "react-grid-system"
import styles from './styles.module.scss'

const Comments = () => {
  return <>
    <div
      className={styles.wrapper}
      style={{
        // background: '#F8F6F6',
        // paddingBottom: 62
      }}>
      <Container className={styles.container}>
        <Row className={styles.comment_wrapper}>
          <Col md={6} className={styles.comment}>
            <h4 className={styles.header}>
              “Comprehensive approach included superb and discreet source enquiries”
            </h4>
            <p className={styles.client}>
              Client – Global vehicle manufacturer
            </p>
          </Col>
          <Col md={6}>
            <p className={styles.text}>
              Urus Advisory's investigation was able to provide our client with valuable information and insights into the potential illegal sales of our products in the Middle East. Their comprehensive approach, including discreet source inquiries, allowed us to identify potential leads on where the KD-kits could have originated. Their findings were instrumental in our ability to take swift action to protect our brand and reputation.
            </p>
          </Col>
        </Row>
        <Row className={styles.comment_wrapper}>
          <Col md={6} className={styles.comment}>
            <h4 className={styles.header}>
            “Very talented guys”
            </h4>
            <p className={styles.client}>
            Client – International industrial holding
            </p>
          </Col>
          <Col md={6}>
            <p className={styles.text}>
            Our experience with Urus Advisory was outstanding. They conducted a thorough investigation into our concerns surrounding a former partner company and provided us with detailed information on their business activities, including how they managed to attempt a fraudulent transaction. Thanks to their expertise in public data retrieval, as well as their discreet human source enquiries and in-depth and technical analysis, we were able to uncover a scheme where the target arranged collusion and fraud.
            </p>
          </Col>
        </Row>
        {/* <Row className={styles.comment_wrapper}>
          <Col offset={{ 'sm': 2 }} sm={10} className={`${styles.even_comment} ${styles.comment}`}>
            <h4 className={styles.header}>
              "Very talented guys"
            </h4>
            <p className={styles.text}>Our experience with Urus Advisory was outstanding. They conducted a thorough investigation into our concerns surrounding a former partner company and provided us with detailed information on their business activities, including how they managed to attempt a fraudulent transaction. Thanks to their expertise in public data retrieval, as well as their discreet human source enquiries and in-depth and technical analysis, we were able to uncover a scheme where the target arranged collusion and fraud.
              
            </p>
            <p className={styles.client}>
              Client – International industrial holding
            </p>
          </Col>
        </Row> */}
      </Container>
    </div>
  </>
}

export default Comments

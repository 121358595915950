import React from 'react'
import { Container, Row, Col } from 'react-grid-system';
import styles from './styles.module.scss'


const Header = ({
  headerSubTitleStyle = {},
  headerWrapperStyles = {},
  buttonStyles = {},
  headerStyles = {},
  headerText = '',
  subHeaderText = '',
  buttonText = 'talk with an expert'
}) => {
  return (
    <>
      <div className={styles.header} style={{ ...headerWrapperStyles }}>
        {/* <NavBar /> */}
        <Container fluid className={styles.container}>
          <Row>
            <Col sm={9}>
              <div className={styles.header_banner}>
                {/* <h1 className={styles.main_title}>Case Study</h1> */}
                <h2 className={styles.title} style={{ ...headerStyles }}>Intellectual Property and Pricing Manipulation</h2>
                <p className={styles.subtitle} style={{ ...headerSubTitleStyle }}>January, 2022</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.three_items}>
        <Container>
          <Row>
            <Col md={4} className={styles.item}>
              <h4>Client</h4>
              <p>International manufacturer of educational construction kits</p>
            </Col>
            <Col md={4} className={styles.item}>
              <h4>Keywords</h4>
              <p>Construction, state tenders, FSU</p>
            </Col>
            <Col md={4} className={styles.item}>
              <h4>Our role</h4>
              <p>Forensic investigation including public and non-public data retrieval and human source
                enquiries</p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Header

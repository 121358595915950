import React, { useState } from "react"
import { Col, Container, Row } from "react-grid-system"
import { useForm } from "react-hook-form"
import { TailSpin } from "react-loader-spinner"
import { Link } from "react-router-dom"
import Checkbox from "../../../../shared/components/Checkbox/Checkbox"
import Input from "../../../../shared/components/Input/Input"
import SuccessModal from "../../../../shared/components/SuccessModal/SuccessModal"
import { createLead, createNotes, createOrg, createProfile } from "../../../../utils/api"

import styles from './styles.module.scss'

const CSForm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoad, setIsLoad] = useState(false)

  const { register, formState: { errors }, trigger, reset, handleSubmit, getValues, setValue } = useForm();
  const onSubmit = async () => {
    setIsLoad(true)
    const first = getValues("firstName");
    const isSubscribed: boolean = getValues('checkbox1')

    const last: string = getValues('lastName')
    const email: string = getValues('mail')
    const phone: string = getValues('phone')
    const profile = await createProfile({
      name: `${first} ${last}`,
      email,
      ...(phone ? { phone } : {}),
      ...(isSubscribed ? { marketing_status: 'true' } : {})
    })

    const orgName: string = getValues('org')
    const owner_id = profile?.id
    const organization = await createOrg({
      owner_id,
      name: orgName
    })

    const lead = await createLead({
      title: `${first} ${last}`,
      person_id: owner_id,
      organization_id: organization.id
    })

    const service = getValues('service')
    if (service) {
      await createNotes({ content: `Service of Interest: ${service}`, lead_id: lead.id })
    }
    reset()
    setIsModalOpen(true)
    setIsLoad(false)
  };


  return <div className={styles.block}>
    <Container>
      <Row>
        <Col lg={6}>
          <div className={styles.leftContainer}>
            <h3>Get in touch with us to benefit from our comprehensive due diligence services.</h3>
            <br />
            {/* <p>Want to talk about background checks or brainstorm about Due Diligence investigation requirements?</p> */}
            {/* <br /> */}
            <p>We are committed to providing prompt assistance, with a response to your inquiry within 24 hours.</p>
            <br />

            <p>Our consultants will promptly assess your needs and follow up accordingly.</p>
          </div>
        </Col>
        <Col lg={6} className={styles.rightContainer}>
          <form onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(onSubmit)()
          }}>
            <Container>
              <Row>
                <Col sm={6} className={styles.left_col}>
                  <Input
                    name="firstName"
                    register={register}
                    rest={{
                      disabled: isLoad,
                      style: {
                        border: errors.firstName ? '1.5px solid #ff000073' : '1px solid #E3D0C9',
                        background: errors.firstName ? '#f2dbdbf0' : '#ffff',
                        margin: '10px 0',
                      },
                      placeholder: 'First Name'
                    }}
                  />
                  <Input
                    register={register}
                    pattern={{
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address",
                    }}
                    name="mail"
                    rest={{
                      disabled: isLoad,
                      style: {
                        margin: '10px 0',
                        border: errors.mail ? '1.5px solid #ff000073' : '1px solid #E3D0C9',
                        background: errors.mail ? '#f2dbdbf0' : '#ffff'
                      },
                      placeholder: 'Company Email',
                    }}
                  />
                  <Input
                    // name="Business"
                    register={register}
                    name={'phone'}
                    required={false}
                    pattern={{
                      value: /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/,
                      message: "invalid phone"
                    }}
                    rest={{
                      disabled: isLoad,
                      style: {
                        margin: '10px 0',
                        border: '1px solid #E3D0C9',
                      },
                      placeholder: 'Business Phone (opt.)',

                    }}
                  />
                </Col>
                <Col sm={6} className={styles.right_col}>
                  <Input
                    register={register}
                    name={'lastName'}
                    rest={{
                      disabled: isLoad,
                      style: {
                        margin: '10px 0',
                        border: errors.lastName ? '1.5px solid #ff000073' : '1px solid #E3D0C9',
                        background: errors.lastName ? '#f2dbdbf0' : '#ffff'
                      },
                      placeholder: 'Last name'
                    }}
                  />
                  <Input
                    register={register}
                    name={'org'}
                    rest={{
                      disabled: isLoad,
                      style: {
                        margin: '10px 0',
                        border: errors.org ? '1.5px solid #ff000073' : '1px solid #E3D0C9',
                        background: errors.org ? '#f2dbdbf0' : '#ffff'
                      },
                      placeholder: 'Organisation'
                    }}
                  />
                  <Input
                    register={register}
                    name={'service'}
                    isDropdown
                    dropdownItems={[
                      'Integrity Due Diligence & Background Screening',
                      'Asset Tracing',
                      'Corporate Investigations',
                      'Strategic and Geopolitical Risk Advisory',
                      'Supply Chains and Sanctions Compliance',
                    ]}
                    onChange={(val: string) => {
                      setValue('service', val)
                    }}
                    rest={{
                      disabled: isLoad,
                      style: {
                        margin: '10px 0',
                        border: errors.service ? '1.5px solid #ff000073' : '1px solid #E3D0C9',
                        background: errors.service ? '#f2dbdbf0' : '#ffff'
                      },
                      placeholder: 'Service of Interest'
                    }}
                  />
                </Col>
                {/* <Row> */}
                <Col sm={12}>
                  <Input
                    name="textArea"
                    rest={{
                      disabled: isLoad,
                      placeholder: 'How may we help you?',
                      style: {
                        margin: '10px 0',
                        border: '1px solid #E3D0C9'
                      }
                    }}
                  />
                </Col>
                {/* </Row> */}
              </Row>
              <Row className={styles.bottom_form_row}>
                <Col>
                  <Checkbox id={'chx-1'} isError={!!errors.checkbox1} register={register} required={true} name={'checkbox1'} label={'Sign up for Urus Advisory Newsletter.'} />
                  <br />
                  <Checkbox id={'chx-2'} isError={!!errors.checkbox2} register={register} name={'checkbox2'} label={<p>Submitting this form confirms your agreement to Urus Advisory <a href="/#/privacy" target="_blank">Privacy Policy</a> and <a href="/cookies" target="_blank">Cookies Policy</a>.</p>} />
                  <button>SEND MESSAGE
                    {isLoad && <TailSpin
                      // visible={false}
                      height="25"
                      width="25"
                      color="#fff"
                      ariaLabel="line-wave-loading"
                      wrapperClass={styles.loader}
                      wrapperStyle={{
                      }}
                    />}
                  </button>
                </Col>
              </Row>
            </Container>
          </form>
        </Col>
      </Row>
    </Container>
    <SuccessModal isOpen={isModalOpen} handleModal={(val: boolean) => setIsModalOpen(val)} />
  </div>
}

export default CSForm

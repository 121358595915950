import React, { createRef, DetailedHTMLProps, HTMLAttributes, useEffect, useState } from 'react'
import { setConfiguration } from 'react-grid-system';
import Header from './shared/components/Header/Header';
import styles from './styles.module.scss'
import './reset-bootstrap.scss'
import Home from './pages/Home/Home';
import {
  BrowserRouter as Router,
  RouterProvider,
  Outlet,
  createHashRouter,
  useLocation,
  useOutlet,
  createBrowserRouter
} from 'react-router-dom';
import NavBar from './shared/components/Navbar/Navbar';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import CookieConsent from 'react-cookie-consent';
import Cookies from './pages/Cookies/Cookies';
import Services from './pages/Service/Services';
import CaseStudies from './pages/CaseStudies/CaseStudies';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import NotFound from './pages/NoteFound/NotFound';
import {
  CSSTransition,
  SwitchTransition
} from "react-transition-group";
import SiteMap from './pages/sitemap';
import ScrollToTop from "react-scroll-to-top";
import StrategicPoliticalRiskAdvisory from './pages/StrategicPoliticalRiskAdvisory/StrategicPoliticalRiskAdvisory';
import SupplyChainsSanctionsCompliance from './pages/SupplyChainsSanctionsCompliance/SupplyChainsSanctionsCompliance';
import CorporateInvestigations from './pages/CorporateInvestigations/CorporateInvestigations';
import AssetTracing from './pages/AssetsTracing/AssetsTracing';
import useScrollToPage from './utils/useScrollToPageRef';
import Arrow from './shared/icons/Arrow';

const App = () => {

  setConfiguration({ containerWidths: [540, 740, 960, 1200, 1200, 1810] });

  const routes = [
    {
      path: "/",
      element: <Home />,
      nodeRef: createRef<HTMLDivElement>()
    },
    {
      path: "/about",
      element: <About />,
      nodeRef: createRef<HTMLDivElement>()
    },
    {
      path: "/services",
      element: <Services />,
      nodeRef: createRef<HTMLDivElement>()
    },
    {
      path: "/contact",
      element: <Contact />,
      nodeRef: createRef<HTMLDivElement>()
    },
    {
      path: "/case-studies",
      element: <CaseStudies />,
      nodeRef: createRef<HTMLDivElement>()
    },
    {
      path: '/strategic-political-risk',
      element: <StrategicPoliticalRiskAdvisory />,
      nodeRef: createRef<HTMLDivElement>(),
    },
    {
      path: '/supply-chains-sanctions',
      element: <SupplyChainsSanctionsCompliance />,
      nodeRef: createRef<HTMLDivElement>(),
    },
    {
      path: '/corporate-investigations',
      element: <CorporateInvestigations />,
      nodeRef: createRef<HTMLDivElement>(),
    },
    {
      path: '/asset-tracing',
      element: <AssetTracing />,
      nodeRef: createRef<HTMLDivElement>(),
    },

  ]
  const [isCookieAccepted, setIsCookieAccepted] = useState(false)

  useEffect(() => {
    setIsCookieAccepted(!!localStorage.getItem('cookieAccept'))
  }, [])
  const Layout = () => {
    const location = useLocation();
    const { nodeRef } = routes.find((route) => route.path === location.pathname) ?? {}
    const { isBottomOfThePage, goToPage } = useScrollToPage()
    const currentOutlet = useOutlet()
    return (
      <>
        <SwitchTransition>
          <CSSTransition
            key={location.pathname}
            timeout={500}
            classNames="page"
            unmountOnExit
          >
            <div ref={nodeRef} className="page" style={{}}>
              <NavBar />
              {currentOutlet}
            </div>
          </CSSTransition>
        </SwitchTransition>
        <ScrollToTop smooth color="white"
          top={500}
          style={{
            background: '#EC5922',
            borderRadius: '50%',
            height: 50,
            width: 50,
            transition: 'all 0.3s',
            bottom: isBottomOfThePage ? 140 : 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }} />
        {isBottomOfThePage && <div
          onClick={() => {
            goToPage()
          }}
          style={{
            background: '#EC5922',
            borderRadius: '50%',
            height: 50,
            width: 50,
            transition: 'all 0.3s',
            bottom: 40,
            position: 'fixed',
            right: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}>
          <Arrow customStyle={{
            transform: 'rotate(-180deg)',
            boxShadow: '0 9px 25px 0 rgba(132, 128, 177, 0.28)'
          }} />
        </div>}
      </>
    )
  };

  const router = createHashRouter([
    {
      element: <Layout />,
      children: [
        ...routes
      ]
    },
    {
      path: "/cookies",
      element: <Cookies />,
    },
    {
      path: '/privacy',
      element: <PrivacyPolicy />
    },
    {
      path: "/sitemap",
      element: <SiteMap />,
    },
    {
      path: "*",
      element: <NotFound />
    }
  ]);

  return (
    <React.StrictMode>
      <div className={styles.test}>
        <RouterProvider router={router} />
      </div>
    </React.StrictMode>
  )
}

export default App

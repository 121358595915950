// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles-module__leads--tcDMS {
  font-family: "Open Sans";
  font-size: 13.8pt;
  font-weight: 700;
  padding-bottom: 160px;
}
.styles-module__leads--tcDMS .styles-module__lead--z7AjB {
  max-width: 265px;
  text-align: center;
}
@media screen and (max-width: 675px) {
  .styles-module__leads--tcDMS .styles-module__lead--z7AjB {
    margin: 0 auto;
  }
}
.styles-module__leads--tcDMS .styles-module__lead--z7AjB img {
  width: 100%;
}
.styles-module__leads--tcDMS h3 {
  font-size: 25.5pt;
  margin-top: 75px;
  margin-bottom: 100px;
}
.styles-module__leads--tcDMS h4 {
  font-size: 17.8pt;
}`, "",{"version":3,"sources":["webpack://./src/pages/CaseStudies/components/Leads/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,iBAAA;EACA,gBAAA;EACA,qBAAA;AACF;AACE;EACE,gBAAA;EACA,kBAAA;AACJ;AAAI;EAHF;IAII,cAAA;EAGJ;AACF;AAFI;EACE,WAAA;AAIN;AAAE;EACE,iBAAA;EACA,gBAAA;EACA,oBAAA;AAEJ;AACE;EACE,iBAAA;AACJ","sourcesContent":[".leads {\n  font-family: 'Open Sans';\n  font-size: 13.8pt;\n  font-weight: 700;\n  padding-bottom: 160px;\n  \n  .lead {\n    max-width: 265px;\n    text-align: center; \n    @media screen and (max-width: 675px) {\n      margin: 0 auto;\n    }\n    img {\n      width: 100%;\n    }\n  }\n\n  h3 {\n    font-size: 25.5pt;\n    margin-top: 75px;\n    margin-bottom: 100px\n  }\n\n  h4 {\n    font-size: 17.8pt;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leads": `styles-module__leads--tcDMS`,
	"lead": `styles-module__lead--z7AjB`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles-module__map--Ematj {
  overflow: hidden;
  max-height: 670px;
}
.styles-module__map--Ematj .styles-module__hovered--thDoO {
  visibility: hidden;
}
.styles-module__map--Ematj .styles-module__projects--GwaU3 {
  position: absolute;
  top: 10px;
  border-radius: 8px;
  z-index: 999;
  left: 10px;
  font-weight: 400;
  padding: 10px 20px;
  font-size: 12pt;
  background: rgba(255, 255, 255, 0.7);
}
.styles-module__map--Ematj .styles-module__animatedMarker--x_yuS {
  transition: all 0.5s;
  cursor: pointer;
}
.styles-module__map--Ematj .styles-module__animatedMarker--x_yuS svg {
  cursor: pointer;
}
@keyframes styles-module__fade-in--tcEcF {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media screen and (max-width: 800px) {
  .styles-module__map--Ematj {
    min-width: 100vw;
    margin: 0 -15px;
    padding: 0 !important;
  }
}
.styles-module__map--Ematj .styles-module__test--9H2vf {
  margin: 0 -15px;
}
.styles-module__map--Ematj .styles-module__test--9H2vf > div {
  padding: 0 !important;
}
.styles-module__map--Ematj svg {
  position: relative;
  top: -80px;
}
@media screen and (max-width: 1599px) {
  .styles-module__map--Ematj svg {
    top: 0px;
  }
}
.styles-module__map--Ematj .styles-module__tooltip--kXSfj {
  background-color: red;
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/components/Map/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EAEA,iBAAA;AAAF;AACE;EACE,kBAAA;AACJ;AACE;EACE,kBAAA;EACA,SAAA;EACA,kBAAA;EACA,YAAA;EACA,UAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,oCAAA;AACJ;AAME;EACE,oBAAA;EACA,eAAA;AAJJ;AAUI;EACE,eAAA;AARN;AAmBE;EACE;IACE,UAAA;EAjBJ;EAoBE;IACE,UAAA;EAlBJ;AACF;AAsBE;EAtDF;IAuDI,gBAAA;IACA,eAAA;IACA,qBAAA;EAnBF;AACF;AAqBE;EACE,eAAA;AAnBJ;AAqBI;EACE,qBAAA;AAnBN;AAuBE;EACE,kBAAA;EACA,UAAA;AArBJ;AAuBI;EAJF;IAKI,QAAA;EApBJ;AACF;AAuBE;EACE,qBAAA;AArBJ","sourcesContent":[".map {\n  overflow: hidden;\n  // margin-top: -120px\n  max-height: 670px;\n  .hovered {\n    visibility: hidden;\n  }\n  .projects {\n    position: absolute;\n    top: 10px;\n    border-radius: 8px;\n    z-index: 999;\n    left: 10px;\n    font-weight: 400;\n    padding: 10px 20px;\n    font-size: 12pt;\n    background: rgb(255 255 255 / 70%);\n  }\n  // g{\n  //   &:hover {\n  //     display: none;\n  //   }\n  // }\n  .animatedMarker {\n    transition: all 0.5s;\n    cursor: pointer;\n    // opacity: 0;\n    // animation: fade-in 0.5s ease-in-out forwards;\n    &:hover {\n      // opacity: 0 !important;\n    }\n    svg {\n      cursor: pointer;\n      circle{\n        &:hover {\n          // display: none;\n          // visibility: hidden;\n        }\n\n      }\n    }\n  }\n\n  @keyframes fade-in {\n    from {\n      opacity: 0;\n    }\n\n    to {\n      opacity: 1;\n\n    }\n  }\n\n  @media screen and (max-width: 800px) {\n    min-width: 100vw;\n    margin: 0 -15px;\n    padding: 0 !important;\n  }\n\n  .test {\n    margin: 0 -15px;\n\n    &>div {\n      padding: 0 !important;\n    }\n  }\n\n  svg {\n    position: relative;\n    top: -80px;\n\n    @media screen and (max-width: 1599px) {\n      top: -0px;\n    }\n  }\n\n  .tooltip {\n    background-color: red;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"map": `styles-module__map--Ematj`,
	"hovered": `styles-module__hovered--thDoO`,
	"projects": `styles-module__projects--GwaU3`,
	"animatedMarker": `styles-module__animatedMarker--x_yuS`,
	"test": `styles-module__test--9H2vf`,
	"tooltip": `styles-module__tooltip--kXSfj`,
	"fade-in": `styles-module__fade-in--tcEcF`
};
export default ___CSS_LOADER_EXPORT___;

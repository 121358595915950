import React, { useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useForm } from 'react-hook-form';
import addresses from '../../../../data/footer';
import SuccessModal from '../../../../shared/components/SuccessModal/SuccessModal';
import { createLead, createProfile } from '../../../../utils/api';
import styles from './styles.module.scss'
import { LineWave, TailSpin } from 'react-loader-spinner'
import BottomFooter from './BottomFooter';
import { Link } from 'react-router-dom';

const Footer = ({
  isMinimized = false
}: {
  isMinimized?: boolean
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { register, getValues, setValue, formState: { errors }, trigger } = useForm();
  const [isLoad, setIsLoad] = useState(false)
  const script = document.createElement("script");
  script.src = "https://webforms.pipedrive.com/f/loader";
  script.defer = true;
  document.body.appendChild(script)

  return <div className={styles.footer} style={{
    ...(isMinimized && { paddingTop: 10, paddingBottom: 30 })
  }}>
    <Container>
      {!isMinimized ? (<Row className={styles.footer_full}>
        <Col md={5} className={styles.section}>
          <div className={styles.logo_section}>
            <img src={require('../../../../../public/images/logo_white.svg')} />
            <p>Premier risk intelligence partner in Eurasia</p>
            <a href='mailto:admin@urusadvisory.com'><b>Email us here.</b></a>
          </div>
          <div className={styles.newsletter_section}>
            <p className={styles.newsletter_section_title}>Sign up to Urus Advisory newsletters</p>
            <p>Sign up now to receive our exclusive regional news digests, delivered straight to your inbox in our own words. Don't miss the latest updates and expert perspectives.</p>
            <div className={styles.sign_up}>
              <input
                disabled={isLoad}
                type="text" placeholder='Company Email' {...register('mail', {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address"
                  }
                })} style={{
                  border: errors.mail ? '1px solid #ff000073' : '1px solid #484581',
                  // color: errors.mail ? 'red' : 'none',
                  background: errors.mail ? 'rgba(255, 255, 255, 0)' : 'rgba(255, 255, 255, 0)',
                  marginRight: '8px'
                }} />
              <button
                disabled={isLoad}
                onClick={async () => {
                  const isValid = await trigger()
                  if (isValid) {
                    const email: string = getValues('mail')
                    setIsLoad(true)
                    const profile = await createProfile({
                      name: email,
                      email,
                      marketing_status: 'true'
                    })
                    await createLead({
                      person_id: profile?.id,
                      title: profile?.name
                    })
                    setIsModalOpen(true)
                    setValue('mail', '');
                    setIsLoad(false)
                  }
                }}>
                SIGN UP
                {isLoad && <TailSpin
                  // visible={false}
                  height="25"
                  width="25"
                  color="#fff"
                  ariaLabel="line-wave-loading"
                  wrapperClass={styles.loader}
                  wrapperStyle={{
                  }}
                />}
              </button>
            </div>
          </div>
          <div className={styles.follow_us}>
            <b>Follow us</b>
            <div  className={styles.follow_us__links}>
              <a href="https://www.linkedin.com/company/urusadvisory/" target={'_blank'}>
                <img className={styles.section_icon} src={require('../../../../../public/images/linkedin.svg')} />
                <span>LinkedIn</span>
              </a>
            </div>
          </div>
        </Col>
        <Col md={3} className={styles.section}>
          <Row>
            <Col>
              <div className={styles.navigation_section}>
                <h5>Navigation</h5>
                <Link to="/services">Services</Link>
                <Link to="/about">About Us</Link>
                <Link to="/contact">Contact</Link>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Row>
            <Col>
              <div className={styles.location_section}>
                <h5>Office locations</h5>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={styles.section}>
              {addresses.col1.map(address => {
                return <div className={`${styles.location_section} ${styles.location_section_items}`}>
                  <b>{address.country}</b>
                  <p>{address.line1} {address.line2}</p>
                </div>
              })}
              {/* {addresses.col2.map(address => {
                return <div className={styles.location_section}>
                  <b>{address.country}</b>
                  <p>{address.line1} {address.line2}</p>
                </div>
              })} */}
            </Col>
          </Row>
        </Col>
      </Row>) : null}
      <BottomFooter />
    </Container>
    <SuccessModal isOpen={isModalOpen} handleModal={setIsModalOpen} />
  </div>
}

export default Footer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles-module__cookies--DJVBw {
  font-family: Inter !important;
  color: #333366;
}
.styles-module__cookies--DJVBw h1 {
  margin-bottom: 50px;
  color: #333366;
}
.styles-module__cookies--DJVBw h2 {
  color: #333366;
}
.styles-module__cookies--DJVBw p {
  padding-bottom: 20px;
  line-height: 25px;
}
.styles-module__cookies--DJVBw strong {
  font-weight: 700;
}
.styles-module__cookies--DJVBw li {
  list-style: disc;
}
.styles-module__cookies--DJVBw ul {
  padding-left: 20px;
  margin-bottom: 20px;
}
.styles-module__cookies--DJVBw a {
  color: #EC5922;
}`, "",{"version":3,"sources":["webpack://./src/pages/PrivacyPolicy/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,cAAA;AACF;AACE;EACE,mBAAA;EACA,cAAA;AACJ;AAEE;EACE,cAAA;AAAJ;AAGE;EACE,oBAAA;EACA,iBAAA;AADJ;AAIE;EACE,gBAAA;AAFJ;AAKE;EACE,gBAAA;AAHJ;AAME;EACE,kBAAA;EACA,mBAAA;AAJJ;AAOE;EACE,cAAA;AALJ","sourcesContent":[".cookies {\n  font-family: Inter !important;\n  color: #333366;\n\n  h1 {\n    margin-bottom: 50px;\n    color: #333366;\n  }\n\n  h2 {\n    color: #333366;\n  }\n\n  p {\n    padding-bottom: 20px;\n    line-height: 25px;\n  }\n\n  strong {\n    font-weight: 700;\n  }\n\n  li {\n    list-style: disc;\n  }\n\n  ul {\n    padding-left: 20px;\n    margin-bottom: 20px;\n  }\n\n  a {\n    color: #EC5922;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cookies": `styles-module__cookies--DJVBw`
};
export default ___CSS_LOADER_EXPORT___;

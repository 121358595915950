import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import { useNavigate, useNavigation } from "react-router";
import useScrollToPage from "../../utils/useScrollToPageRef";
import Footer from "../Home/components/Footer/Footer";
import Meet from "./component/Meet/Meet";

const About = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      // @ts-ignore
      behavior: 'instant'
    });
  }, [])

  const componentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate()

  return <div id="about" ref={componentRef}>
    <Meet />
    <Footer isMinimized={false} />
  </div>
}

export default About;

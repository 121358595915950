import React, { useEffect } from "react"
import { Col, Container, Row, useScreenClass, } from "react-grid-system"
import { Link } from "react-router-dom"
import styles from './styles.module.scss'

const TextBubbleNew = (props: {
  leftSection: JSX.Element
  isBlue?: boolean
  rightTitle?: string
  isHrEnable?: boolean
  bubblesRight?: boolean
  direction: 'row' | 'column'
  bubbleItems: string[][]
  bubbleItemsXS?: string[]
  styleType?: 'bubble' | 'points'
}) => {
  const screenClass = useScreenClass();

  const { styleType = 'bubble', isHrEnable = true, isBlue = false, bubblesRight = true } = props
  return <>
    {isHrEnable && <hr style={{
      border: '0.5px solid rgb(158 158 158 / 9%)',
      margin: 0
    }} />}
    <div style={{
      background: !isBlue ? '#fff' : '#14346F',
    }} className={styles.section}>
      <Container style={{
        maxWidth: 1156.7
      }}

      >
        <Row style={{
          alignItems: 'stretch',
        }}>
          <Col lg={6}
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '0 !important',
            }}
          >
            <div
              style={{
                color: !isBlue ? 'black' : '#fff'
              }}
              className={`${styles.left_section} ${styles[styleType + '_section']} ${styles[(props.direction ?? 'row') + '_left_section']}`}>
              {/* {props.leftSection} */}
              <h3 className={styles.title}>
                Together
              </h3>
              <p>
                The business world has become more connected but also more unknown. <b>Reliable local partners are needed everywhere</b> - to mitigate risks, assess opportunities, or conduct investigations into potential legal or regulatory issues.
              </p>
              <p>
                The need for risk intelligence and due diligence has become crucial in today's business landscape.
              </p>
              <p>
                <b>For almost a decade, our growing expertise across much of Eurasia has earned the trust of more than 150 clients, supporting their growth over time.</b>
              </p>
            </div>
          </Col>
          <Col lg={6} style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <div className={`${styles.right_section} ${styles[styleType + '_right_section']} ${styles[(props.direction ?? 'row') + '_right_section']}`}>
              <h4 className={`${styles[(props.direction ?? 'row') + '_title']} ${styles[styleType + '_title']}`}
                style={{
                }}
              ><span>{props.rightTitle}</span></h4>
              <div className={`${styles.bubble_container} ${styles[props.direction ?? 'row']} ${styles[styleType + '_container']} ${styles['container_' + screenClass]}`}>
                {screenClass === 'sm' || screenClass === 'xs' ? props.bubbleItems.flat().map(item => {
                  return <div className={`${styles.item} ${styles[styleType]}`}>
                    <span
                      style={{
                        ...(isBlue && styleType == "bubble" && { background: '#EC5922' })
                      }}
                    >
                      {item}
                    </span>
                  </div>
                }) : props.bubbleItems.map(row => {
                  return <div className={styles.row}>{row.map(item => <div className={`${styles.item} ${styles[styleType]}`}>
                    {
                      <span
                        style={{
                          ...(isBlue && styleType == "bubble" && { background: '#EC5922' })
                        }}
                      >
                        {item}
                      </span>

                    }
                  </div>)}</div>
                })}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>

  </>
}

export default TextBubbleNew

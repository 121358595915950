import React, { useEffect } from 'react';

import Footer from "../Home/components/Footer/Footer"
import Header from '../../shared/components/Header_old/Header';
import HeaderSpacing from '../../shared/components/HeaderSpace/HeaderSpace';
import Form from "../Home/components/Form/Form"
import Bubble from '../Service/components/Bubble/Bubble';
import SpecialBlog from '../Service/components/SpecialBlog/SpecialBlogNoPhoto';
import TextBubble from '../../shared/components/TextBubble/TextBubble';
import useScrollToPage from '../../utils/useScrollToPageRef';
import TextBubbleSubPages from '../../shared/components/TextBubble/TextBubbleSubPages';

const CorporateInvestigations = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      // @ts-ignore
      behavior: 'instant'
    });
  }, [])

  // useScrollToPage({ nextPageId: '/strategic-political-risk' })


  return (
    <div>
      <HeaderSpacing />
      <Header
        headerText={`Corporate Investigations`}
        subHeaderText='Insightful Investigations, Informed Decisions'
        headerStyles={{
          // fontSize: '36pt',
          // lineHeight: '40pt'
        }}
        headerSubTitleStyle={{
          // fontSize: '24pt',
          // lineHeight: '30pt'
        }}
        headerWrapperStyles={{
          background: '#191736'
        }}
        buttonText={'Request a service '}
        buttonStyles={{
          fontWeight: 700
        }}
      />
      <TextBubbleSubPages
        leftSection={<>
          <h3 style={{
            textAlign: 'left'
          }}>
            Corporate Investigations
          </h3>
          <p>
            Effective corporate investigations can solve huge conundrums for companies and their stakeholders. Global regulatory frameworks obligate many companies to pre-empt official enquiries into their internal or external trade activities, while shareholders being deprived of assets through dishonest worker moves can destroy value.
          </p>
          <p>
            Urus Advisory conducts efficient, discreet, and results-led investigations into a huge range of cases that perplex or worry firms. We work with in-house and external counsel, key authorised staff, and units of broader investigative teams to tackle delicate issues. Our regular work examines allegations or suspicions of fraud, rule-breaking and detecting conspiracy… and even investigating collusion and suspected false testimony to the courts.
          </p>
          <p>
            The realms of digital currency, intellectual property rights, and the art industry are marked by their rapid evolution and complex legal frameworks. These sectors require unique investigative approaches capable of adapting to their specific nuances. Our arsenal of sophisticated techniques and extensive experience deliver solutions that are custom-fit to your needs.
          </p>
          <p>
            Corporate integrity is the basis of sustainable success. Should you have concerns about compliance breaches, conflicts of interest, fraudulent activities, or illicit financial transactions within your company, our comprehensive forensic investigations offer a pathway to not only uncover but also prevent future violations.
          </p>
        </>}
        rightTitle={'The services available for our clients are:'}
        direction={'column'}
        bubbleItems={[
          'Fraud, malfeasance & regulatory investigations',
          'Forensic investigations',
          'Internal investigations',
          'Crypto fraud detection & digital investigations',
          'Intellectual property rights infringements',
          'Special situations investigations'
        ]}
      />
      <Bubble
        direction={"column"}
        styleType='points'
        title='Why choose Urus Advisory to conduct your Corporate Investigation?'
        bubbleItems={[{
          title: 'Intelligent approach',
          description: 'Our approach to investigation combines comprehensive analysis, out-of-the box thinking, and flexibility, facilitating our involvement at any stage of a project, whether by joining your team or taking full control on a turnkey basis.',
        },
        {
          title: 'Cross-border investigations',
          description: 'We boast extensive experience in leading complex investigative strands across a wide range of Eurasian jurisdictions, ensuring comprehensive coverage and inside view for our clients.',
        },
        {
          title: 'Extensive source networks',
          description: 'Over the last decade, we have assembled an expansive pool of sources with expertise across numerous industries, ready to provide you with essential insights within a limited timeframe.',
        },
        {
          title: 'Latest OSINT techniques',
          description: 'In our investigations, we use cutting-edge tools and services for data screening and media monitoring, complemented by our know-how techniques in source enquiries.',
        },
        ]} />
      <SpecialBlog
        quote={'Between information and a strategic decision lies good analytics; between risk and an effective investigation - an expert approach. Between failure and success, there may be only one step. We can help you take the right step.'}
        author={'Denis S - Head of Corporate Investigations'}
      />
      <Form serviceOfInterest='Corporate Investigations' />
      <Footer isMinimized={false} />
    </div>
  );
};

export default CorporateInvestigations;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles-module__header--8B4K8 {
  font-family: "Lora";
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 0;
  color: #FF783F;
}

.styles-module__text--fozwU {
  font-family: Poppins;
  color: #191736;
  font-size: 16px;
  margin-bottom: 0;
  line-height: 19px;
  max-width: 225px;
  font-weight: 400;
  margin: 0 auto;
}

.styles-module__col_wrapper--WYOHP {
  padding: 60px 0;
}
.styles-module__col_wrapper--WYOHP:not(:first-child)::before {
  content: "";
  position: absolute;
  left: 0;
  height: 135px;
  width: 1px;
  background-color: #D7D7D7;
}

.styles-module__container--I4GaU {
  background-color: #fff;
  filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.25));
}

.styles-module__wrapper--AbVWK {
  background-color: #FF783F;
  padding-top: 80px;
  color: #fff;
  text-align: center;
  padding-bottom: 80px;
}
@media screen and (max-width: 767px) {
  .styles-module__wrapper--AbVWK {
    padding: 45px 27.5px 45px;
  }
}`, "",{"version":3,"sources":["webpack://./src/shared/components/FourColumns/styles.module.scss","webpack://./src/shared/_colors.scss"],"names":[],"mappings":"AAEA;EAEE,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,aAAA;EACA,cCRkB;ADMpB;;AAKA;EACE,oBAAA;EACA,cCVW;EDWX,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;AAFF;;AAKA;EACE,eAAA;AAFF;AAKI;EACE,WAAA;EACA,kBAAA;EACA,OAAA;EACA,aAAA;EACA,UAAA;EACA,yBC1BO;ADuBb;;AAQA;EACE,sBCjCY;EDkCZ,oDAAA;AALF;;AAQA;EACE,yBC3CkB;ED4ClB,iBAAA;EACA,WAAA;EACA,kBAAA;EACA,oBAAA;AALF;AAME;EANF;IAOI,yBAAA;EAHF;AACF","sourcesContent":["@import '../../colors.scss';\n\n.header {\n  // margin-top: 85px;\n  font-family: 'Lora';\n  font-size: 40px;\n  font-weight: 700;\n  margin-bottom: 20px;\n  margin-top: 0;\n  color: $primary-btn-color\n}\n\n.text {\n  font-family: Poppins;\n  color: $blue-color;\n  font-size: 16px;\n  margin-bottom: 0;\n  line-height: 19px;\n  max-width: 225px;\n  font-weight: 400;\n  margin: 0 auto;\n}\n\n.col_wrapper {\n  padding: 60px 0;\n\n  &:not(:first-child) {\n    &::before {\n      content: \"\";\n      position: absolute;\n      left: 0;\n      height: 135px;\n      width: 1px;\n      background-color: $light-gray;\n    }\n  }\n}\n\n.container {\n  background-color: $white-color;\n  filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.25));\n}\n\n.wrapper {\n  background-color: $primary-btn-color;\n  padding-top: 80px;\n  color: #fff;\n  text-align: center;\n  padding-bottom: 80px;\n  @media screen and (max-width: 767px) {\n    padding: 45px 27.5px 45px;\n  }\n}\n","$primary-color: #EC5922;\n$primary-btn-color: #FF783F;\n$base-color: #0E0E1B;\n$nav-color: #333366;\n$blue-color: #191736;\n$primary-bg-color: #191736;\n$white-color: #fff;\n$light-gray: #D7D7D7;\n$bg-light-blue: #484581;\n$secondary-bg: #EEE0DA;\n$primary-bg: #FFF5F1\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `styles-module__header--8B4K8`,
	"text": `styles-module__text--fozwU`,
	"col_wrapper": `styles-module__col_wrapper--WYOHP`,
	"container": `styles-module__container--I4GaU`,
	"wrapper": `styles-module__wrapper--AbVWK`
};
export default ___CSS_LOADER_EXPORT___;

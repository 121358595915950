import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import styles from './styles.module.scss'
interface CustomInputProps {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  type?: 'email' | 'text' | 'password'
  style?: React.CSSProperties
  pattern?: {
    value: RegExp,
    message: string
  }
}
type InputType = {
  register?: UseFormRegister<FieldValues>
  isDropdown?: boolean
  onChange?: (value: string) => void;
  defaultValue?: string
  dropdownItems?: string[]
  name: string
  required?: boolean
  rest?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
  pattern?: {
    value: RegExp,
    message: string
  }
}

const CustomInput = ({
  register,
  name,
  rest,
  pattern,
  required = true,
  isDropdown = false,
  dropdownItems = [],
  defaultValue,
  onChange
}: InputType) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  useEffect(() => {
    if (defaultValue) {
      onChange?.(defaultValue)
    }
  }, [])

  return (
    <div style={{
      position: 'relative'
    }}>
      <input
        {
        ...register?.(name, {
          required,
          pattern
        })
        }
        {...rest}
        readOnly={isDropdown && !!dropdownItems}
        style={{
          cursor: isDropdown && !!dropdownItems ? 'pointer' : 'inherit',
          textOverflow: isDropdown && !!dropdownItems ? 'ellipsis' : 'inherit',
          overflow: isDropdown && !!dropdownItems ? 'hidden' : 'inherit',
          ...rest?.style,
          

        }}
        onClick={() => {
          if (isDropdown && dropdownItems)
            setIsDropdownOpen(prev => !prev)
        }}
        // onFocus={() => {
        //   if (isDropdown && dropdownItems)
        //     setIsDropdownOpen(prev => !prev)
        // }}
        onBlur={() => {
          if (isDropdown && dropdownItems)
            setTimeout(() => setIsDropdownOpen(false), 500)
        }}
        className={styles.input}
      />
      {isDropdown && dropdownItems && (
        <div className={styles.dropdown} style={{
          transition: 'all 0.3s',
          overflow: 'hidden',
          opacity: isDropdownOpen ? 1 : 0,
          height: isDropdownOpen ? 'auto' : 0
        }}>
          {dropdownItems?.map(item => {
            return <span onClick={() => {
              onChange?.(item)
              setIsDropdownOpen(false)
            }}>{item}</span>
          })}
        </div>
      )
      }
    </div >
  );
};

export default CustomInput;

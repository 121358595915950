// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles-module__img--d2F_3 {
  height: 270px;
  overflow: hidden;
}
.styles-module__img--d2F_3 img {
  width: 100%;
  width: 100%;
  object-fit: cover;
  height: 100%;
  object-position: 0 80%;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/PreviewImage/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;AACF;AAAE;EACE,WAAA;EACA,WAAA;EACA,iBAAA;EACA,YAAA;EACA,sBAAA;AAEJ","sourcesContent":[".img {\n  height: 270px;\n  overflow: hidden;\n  img{\n    width: 100%;\n    width: 100%;\n    object-fit: cover;\n    height: 100%;\n    object-position: 0 80%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"img": `styles-module__img--d2F_3`
};
export default ___CSS_LOADER_EXPORT___;

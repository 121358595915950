import React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import styles from './styles.module.scss'
import addresses from '../../../../data/footer'
const OfficeLocation = () => {
  return <div className={styles.office}>
    <Container className={styles.office_container} style={{
      maxWidth: 1000,
      paddingTop: 180,
      paddingBottom: 180,
    }}>
      <Row>
        {/* <Col md={3} xs={0}></Col> */}
        <Col md={5}>
          <h3 className={styles.office_location_header}>Office locations</h3>
        </Col>
        <Col md={7}>
          {addresses.col1.map(address => {
            return <Row className={styles.office_location}>
              <Col md={4} style={{
                paddingLeft: 0
              }}>
                <h5>{address.country}</h5>
              </Col>
              <Col md={8}>
                <p>{address.line1} {address.line2}</p>
              </Col>
              <hr />
            </Row>
          })}
        </Col>
      </Row>
      <Row>
        {/* {addresses.col1.map(address => {
          return <Col sm={4} className={styles.office_location}>
            <h5>{address.country}</h5>
            <p>{address.line1} <br /> {address.line2}</p>
          </Col>
        })} */}
        {/* {addresses.col2.map(address => {
          return <Col sm={4}  className={styles.office_location}>
            <h5>{address.country}</h5>
            <p>{address.line1} <br /> {address.line2}</p>
          </Col>
        })} */}
        {/* <Col sm={4}>
          <div className={styles.location_item}>
            <h5>United Kingdom</h5>
            <p>207 Regent Street,<br />
              London W1B 3HH</p>
          </div>
        </Col>
        <Col sm={4}>
          <div className={styles.location_item}>
            <h5>Latvia</h5>
            <p>Audēju iela 15, <br />
              Centra rajons, Rīga, LV-1050</p>
          </div>
        </Col> */}
        {/* <Col sm={4}>
          <div className={styles.location_item}>
            <h5>Georgia</h5>
            <p>36 Aigyptou Avenue,<br />
              6030 Larnaca, Cyprus</p>
          </div>
        </Col> */}
      </Row>
      <Row>
        {/* <Col sm={4}>
          <div className={styles.location_item}>
            <h5>Türkiye</h5>
            <p>1 Scotts Road #24-10,<br />
              Shaw Centre, Singapore 228208</p>
          </div>
        </Col>
        <Col sm={4}>
          <div className={styles.location_item}>
            <h5>Kazakhstan</h5>
            <p>36 Aigyptou Avenue,<br />
              6030 Larnaca, Cyprus</p>
          </div>
        </Col> */}
      </Row>
    </Container>
  </div>
}


export default OfficeLocation

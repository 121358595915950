// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles-module__solution--pnuxT {
  font-family: "Open Sans";
  font-size: "25.5pt";
  padding: 77px 0;
}
.styles-module__solution--pnuxT img {
  object-fit: cover;
}
.styles-module__solution--pnuxT .styles-module__right_section--eWuSH {
  padding-left: 80px;
}
@media screen and (max-width: 992px) {
  .styles-module__solution--pnuxT .styles-module__right_section--eWuSH {
    padding-left: 0px;
  }
}
.styles-module__solution--pnuxT h3 {
  text-align: left;
  font-weight: 700;
}
.styles-module__solution--pnuxT b {
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/pages/CaseStudies/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,mBAAA;EACA,eAAA;AACF;AAAE;EACE,iBAAA;AAEJ;AAAE;EACE,kBAAA;AAEJ;AADI;EAFF;IAGE,iBAAA;EAIF;AACF;AADE;EACE,gBAAA;EACA,gBAAA;AAGJ;AAAE;EACE,gBAAA;AAEJ","sourcesContent":[".solution {\n  font-family: 'Open Sans';\n  font-size: '25.5pt';\n  padding: 77px 0;\n  img{\n    object-fit: cover;\n  }\n  .right_section {\n    padding-left: 80px;\n    @media screen and (max-width: 992px) {\n    padding-left: 0px;\n    }\n  }\n\n  h3 {\n    text-align: left;\n    font-weight: 700;\n  }\n\n  b {\n    font-weight: 700;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"solution": `styles-module__solution--pnuxT`,
	"right_section": `styles-module__right_section--eWuSH`
};
export default ___CSS_LOADER_EXPORT___;

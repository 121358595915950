// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles-module__get_in_touch--gXTvr {
  padding: 90px 0 75px;
  color: #fff;
  background-color: #191736;
  text-align: center;
}
.styles-module__get_in_touch--gXTvr h3 {
  font-size: 44pt;
  font-family: "Open Sans";
  text-align: center !important;
  font-weight: 300;
}
.styles-module__get_in_touch--gXTvr p {
  font-size: 16pt;
  font-family: "Inter";
  line-height: 20pt;
  font-weight: 300;
  text-align: center;
}
.styles-module__get_in_touch--gXTvr button {
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/pages/CaseStudies/components/GetInTouch/styles.module.scss","webpack://./src/shared/_colors.scss"],"names":[],"mappings":"AAEA;EACE,oBAAA;EACA,WCEY;EDDZ,yBCDW;EDEX,kBAAA;AADF;AAGE;EACE,eAAA;EACA,wBAAA;EACA,6BAAA;EACA,gBAAA;AADJ;AAIE;EACE,eAAA;EACA,oBAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;AAFJ;AAIE;EACE,gBAAA;AAFJ","sourcesContent":["@import '../../../../shared/_colors.scss';\n\n.get_in_touch {\n  padding: 90px 0 75px;\n  color: $white-color;\n  background-color: $blue-color;\n  text-align: center;\n\n  h3 {\n    font-size: 44pt;\n    font-family: 'Open Sans';\n    text-align: center !important;\n    font-weight: 300;\n  }\n\n  p {\n    font-size: 16pt;\n    font-family: 'Inter';\n    line-height: 20pt;\n    font-weight: 300;\n    text-align: center;\n  }\n  button {\n    font-weight: 700;\n    // display: flex;\n  }\n}\n","$primary-color: #EC5922;\n$primary-btn-color: #FF783F;\n$base-color: #0E0E1B;\n$nav-color: #333366;\n$blue-color: #191736;\n$primary-bg-color: #191736;\n$white-color: #fff;\n$light-gray: #D7D7D7;\n$bg-light-blue: #484581;\n$secondary-bg: #EEE0DA;\n$primary-bg: #FFF5F1\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"get_in_touch": `styles-module__get_in_touch--gXTvr`
};
export default ___CSS_LOADER_EXPORT___;

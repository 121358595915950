import React from "react"
import { Col, Container, Row } from "react-grid-system"
import styles from './nophoto.module.scss'
const Bubble = ({ quote, author }: {
  quote: string
  author: string
}) => {
  return <div className={styles.section}>
    <Container>
      <Row className={styles.wrapper}>
        <Col lg={12}>
          <div className={styles.container}>
            <p>{quote}</p>
          </div>
          <div>
            <br />
            <br />
            <p className={styles.author}><b>{author}</b></p>
          </div>
        </Col>
        <img className={styles.quotes} src={require('../../../../../public/images/quotes-new.svg')} />
      </Row>
    </Container>
  </div>
}

export default Bubble

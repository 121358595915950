import React from "react"
import { Col, Container, Row } from "react-grid-system"
import styles from './styles.module.scss'
const Bubble = (props: {
  title?: string
  direction: 'row' | 'column'
  bubbleItems: { title: string; description: string }[]
  styleType?: 'bubble' | 'points'
}) => {

  const { styleType = 'bubble' } = props
  return <div className={styles.section}>
    <Container style={{
      maxWidth: 1156.7
    }}>
      <Row style={{
        // alignItems: 'center'
      }}>
        <Col lg={6} className={styles.left_section}>
          <h4>{props.title}</h4>
        </Col>
        <Col lg={6}>
          <div className={styles.right_section}>
            <div className={`${styles.bubble_container} ${styles[props.direction ?? 'row']} ${styles[styleType + '_container']}`}>
              {props.bubbleItems.map((item, index) => {
                return <div className={styles.item_wrapper}>
                  <div className={`${styles.item} ${styles[styleType]}`}>
                    <h4>{item.title}</h4>
                    <p>{item.description}</p>
                    {/* <span className={styles.line} /> */}
                  </div>
                </div>
              })}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
}

export default Bubble

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles-module__cookies--cMWEP {
  font-family: Inter !important;
  color: #333366;
}
.styles-module__cookies--cMWEP h1 {
  margin-bottom: 50px;
  color: #333366;
}
.styles-module__cookies--cMWEP p {
  padding-bottom: 20px;
  line-height: 25px;
}
.styles-module__cookies--cMWEP .styles-module__bottom_bar--WLMnt p {
  padding-bottom: 0 !important;
}
.styles-module__cookies--cMWEP strong {
  font-weight: 700;
}
.styles-module__cookies--cMWEP li {
  list-style: disc;
}
.styles-module__cookies--cMWEP ul {
  padding-left: 20px;
  margin-bottom: 20px;
}
.styles-module__cookies--cMWEP a {
  color: #EC5922;
}`, "",{"version":3,"sources":["webpack://./src/pages/Cookies/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,cAAA;AACF;AACE;EACE,mBAAA;EACA,cAAA;AACJ;AAEE;EACE,oBAAA;EACA,iBAAA;AAAJ;AAGE;EACE,4BAAA;AADJ;AAIE;EACE,gBAAA;AAFJ;AAIE;EACE,gBAAA;AAFJ;AAIE;EACE,kBAAA;EACA,mBAAA;AAFJ;AAKE;EACE,cAAA;AAHJ","sourcesContent":[".cookies {\n  font-family: Inter !important;\n  color: #333366;\n\n  h1 {\n    margin-bottom: 50px;\n    color: #333366;\n  }\n\n  p {\n    padding-bottom: 20px;\n    line-height: 25px;\n  }\n\n  .bottom_bar p {\n    padding-bottom: 0 !important;\n  }\n\n  strong {\n    font-weight: 700;\n  }\n  li{\n    list-style: disc;\n  }\n  ul {\n    padding-left: 20px;\n    margin-bottom: 20px;\n  }\n\n  a {\n    color: #EC5922;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cookies": `styles-module__cookies--cMWEP`,
	"bottom_bar": `styles-module__bottom_bar--WLMnt`
};
export default ___CSS_LOADER_EXPORT___;

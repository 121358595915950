import React from "react"

const Text = ({
  children = <></>,
  styles = {},
  className
}: {
  children: any
  styles: object
  className?: string
}) => {
  return <div className={className} style={{ paddingBottom: 76, ...styles }}>
    {children}
  </div>
}

export default Text

import React from "react";
import { Col, Container, Row } from "react-grid-system";
import HeaderSpacing from "../../shared/components/HeaderSpace/HeaderSpace";
import PreviewImage from "../../shared/components/PreviewImage/PreviewImage";
import CSForm from "../Home/components/CS-Form/Form";
import Explore from "./components/Explore/Explore";
import GetInTouch from "./components/GetInTouch/GetInTouch";
import Header from "./components/Header/Header";
import Leads from "./components/Leads/Leads";
import styles from './styles.module.scss'

const CaseStudies = () => {
  return <>
    <HeaderSpacing />
    <PreviewImage />
    <CSForm />
    <Header />
    <div style={{
      padding: '102px 0 106px',
      fontFamily: 'Open Sans'
    }}>
      <Container>
        <Row>
          <Col>
            <h2 style={{
              fontSize: '25.5pt',
              marginBottom: 83,
              fontWeight: 700
            }}>
              Situation
            </h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <p style={{
              fontSize: '18pt',
              fontWeight: 400,
              lineHeight: '20pt'
            }}>
              Urus Advisory was approached by our client over concerns surrounding a distributor company that often wins state tenders to supply the client's products at significantly lower prices than on the open market.
              <br />
              <br />
              <br />
              The client requested details on the company’s business activities, including who its ultimate beneficiary was and whether the company was part of a wider group, as well as how it manages to ‘dump’ prices.
              <br />
              <br />
              <br />
              The client also believed that a Member of the Regional Legislative body - whose assistant served as the General Director (CEO) of the company - may have been an ultimate beneficiary of the company in question. Urus Advisory was asked to assess the validity of this theory.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
    <div
      className={styles.solution}
      style={{
        padding: '102px 0 0',
        fontFamily: 'Open Sans'
      }}>
      <Container>
        <Row>
          <Col lg={6} style={{
            overflow: 'hidden',
            maxHeight: 760
          }}>
            <img style={{
              // position: 'absolute'
              height: '100%',
              width: '100%'
            }}
              src={require('../../../public/images/solution_img.jpg')} alt="" />
          </Col>
          <Col lg={6}>
            <div className={styles.right_section}>
              <h3 style={{
                fontSize: '25.5pt',
              }}>Our solution</h3>
              <p style={{
                fontSize: '18pt',
                fontWeight: 400,
                lineHeight: '20pt'
              }}>
                We conducted <b>in-depth analysis of publicly available data</b> in Russia, including the State Procurement portal and various public registries such as the Unified State Register of Legal Entities, the Unified State Register of Real Estate Property, Register of Trademarks, court case archives, domain databases and others.
                <br />
                <br />
                <br />
                Urus Advisory also <b>spoke to sources with access to non-public databases and conducted discreet enquiries among relevant industry figures</b> such as former employees of the company and partners of the CEO as well as industry experts and heads of rival companies and lawyers.
                <br />
                <br />
                <br />
                The client also believed that a Member of the Regional Legislative body - whose assistant served as the General Director (CEO) of the company - may have been an ultimate beneficiary of the company in question. Urus Advisory was asked to assess the validity of this theory.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <div style={{
        padding: '102px 0 106px',
        fontFamily: 'Open Sans'
      }}>
        <Container>
          <Row>
            <Col>
              <h2 style={{
                fontSize: '25.5pt',
                marginBottom: 83,
                fontWeight: 700
              }}>
                Results
              </h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <p style={{
                fontSize: '18pt',
                fontWeight: 400,
                lineHeight: '20pt'
              }}>
                The client was provided with an extensive list of indiviuals only formally affiliated with the company (they in fact work for other companies), as well as the names of its actual management.
                <br />
                <br />
                <br />

                Urus Advisory obtained and analysed key information on the company’s export/import operations, as well as the state contracts of all companies belonging to the group;
                <br />
                <br />
                <br />

                <b>We uncovered the scheme that the company applied to dump prices when participating in state tenders: it transpired that it did not purchase our client's products, but instead assembled very similar kits from Chinese spare parts at its own production sites.</b>
                Upon signing state contracts, the company provided its own, unregistered trademark and state customers were satisfied with this approach, given the cost savings.
                <br />
                <br />
                <br />
                Urus Advisory established that the company in question was, in fact, part of a large group engaged in both trading and manufacturing enterprises in various Russian regions.
                <br />
                <br />
                <br />
                <b>It was possible to establish the names of the real beneficiaries of the company in question and to prove that the CEO is only a nominee.</b> Through our source enquiries, it was possible to find out the professional background, sources of wealth, reputation and other relevant information about the CEO and the ultimate beneficiaries of the company.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <Leads />
      <GetInTouch />
      <Explore />
    </div>
  </>
}

export default CaseStudies;

import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import DropdownIcon from "../../icons/Logo";
import styles from './styles.module.scss'

const CustomDropdown = (props: {
  options: {
    label: string
    value: string
  }[]
  shouldBeOpenedByDefault?: boolean
  forcedActiveItem?: {
    label: string
    value: string
  }
  defaultValue: string
  click: (val: string) => void
  activeStyles: React.CSSProperties
}) => {
  const { defaultValue = 'Select...', shouldBeOpenedByDefault = false, options, click, activeStyles = {
    fontWeight: 'medium'
  }, forcedActiveItem } = props
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [dropdownAvailable, setIsDropdownAvailable] = useState(true)
  useEffect(() => {
    // setTimeout(() => setIsDropdownAvailable(true), 3000)
  }, [])
  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: {
    label: string
    value: string
  }) => {
    setSelectedOption(option.value);
    setIsOpen(false);
    click(option.value)
  };

  const location = useLocation();

  const isItemSelected = useMemo(() => options.findIndex(option => location.pathname.indexOf(option.value) == 0) !== -1, [location])
  return (
    <div className={styles.dropdown}>
      <div className={styles.dropdownHeader} style={{
        ...(!isOpen && isItemSelected ? { color: '#EC5922' } : {})
      }} onClick={handleToggleDropdown}>
        {defaultValue} <span>{
          <DropdownIcon color={!isOpen && isItemSelected ? '#EC5922' : '#fff'} customStyle={(isOpen ? { transform: 'rotate(180deg)', transition: 'all 0.3s' } : { transition: 'all 0.3s' })} />
        }</span>
      </div>
      {dropdownAvailable && (
      <ul className={`${styles.dropdownOptions}  ${isOpen && styles.open || styles.close}`}>
        {options.map((option, index) => (
          <li
            key={index}
            onClick={() => handleOptionClick(option)}
            style={((selectedOption == option.value) || (forcedActiveItem?.value == option.value)) && activeStyles || {}}
          >
            {option.label}
          </li>
        ))}
      </ul>
      )}

    </div>
  );
}

export default CustomDropdown

import axios from "axios"
import React, { useEffect, useState } from "react"
import { Container, Row, Col, setConfiguration } from "react-grid-system"
import HeaderSpacing from "../../shared/components/HeaderSpace/HeaderSpace"
// import { Form } from "react-router-dom"
import PreviewImage from "../../shared/components/PreviewImage/PreviewImage"
import CSForm from "../Home/components/CS-Form/Form"
import Footer from "../Home/components/Footer/Footer"
import OfficeLocation from "./component/OfficeLocation/OfficeLocation"
import Text from './component/Text/Text'
import styles from './styles.module.scss'

const Contact = () => {
  const script = document.createElement("script");
  script.src = "https://webforms.pipedrive.com/f/loader";
  script.defer = true;
  document.body.appendChild(script);
  const sendForm = () => {
    axios.post('https://webforms.pipedrive.com/f/6k35oz7StLl7H2ndT2iq9LY8U6tjpEYDXTp1S4XCGOei1aY36o2gE7zb3KiZ6ptwUX', {

    })
    // https://webforms.pipedrive.com/f/6k35oz7StLl7H2ndT2iq9LY8U6tjpEYDXTp1S4XCGOei1aY36o2gE7zb3KiZ6ptwUX
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      // @ts-ignore
      behavior: 'instant'
    });
  }, [])
  return (
    <>
      <div style={{
        // background: '#fff'
      }}>
        <HeaderSpacing />
        <hr style={{
          border: '0.5px solid rgb(158 158 158 / 9%)',
          margin: 0
        }} />
      </div>
      <div style={{
      }}>
        <Container style={{
        }}>
          <Row>
            <Col xs={12} className={styles.header}>
              <Text className={styles.header} styles={{
                paddingBottom: 180,
                paddingTop: 180
              }}>
                <p style={{
                  fontSize: '24px',
                  fontWeight: 400,
                  lineHeight: '29px',
                  fontFamily: 'Lora',
                  color: '#191736',
                  letterSpacing: '0.25px'
                }}>
                  With almost 10 years of experience, Urus Advisory has been helping clients navigate the complexities of Eurasia, providing risk intelligence and due diligence expertise. We have built trust with over 150 clients and are committed to supporting your growth every step of the way. Get in touch with us today to see how we can assist you.
                </p>
              </Text>
              <hr style={{
                border: '0.5px solid rgb(158 158 158 / 9%)',
                margin: 0
              }} />
            </Col>
          </Row>
        </Container>
      </div>

      <div 
      className={styles.comment}
      style={{
        background: '#FFF5F1',
        paddingTop: 180,
        paddingBottom: 180
      }}>
        <Container style={{
          // maxWidth: 1200
        }}>
          <Row>
            <Col md={6}>
              <Text className={styles.title_comment}  styles={{
              }}>
                <>
                  <p  style={{
                    fontSize: '32px',
                    fontFamily: 'Lora',
                    // line-height: 15pt;
                    letterSpacing: '-1px',
                    fontWeight: 400,
                    fontStyle: 'italic',
                    marginBottom: 24
                  }}>“Comprehensive approach included superb and discreet source enquiries”</p>

                  <b 
                  className={styles.hidden_xs}
                  style={{
                    fontSize: '14px',
                    letterSpacing: '-0.4px',
                    fontWeight: 600,
                  }}><i> Client – Global vehicle manufacturer</i></b>
                </>
              </Text>
            </Col>
            <Col md={6}>
              <p style={{
                fontSize: '16px',
                letterSpacing: '-0.2px',
                marginBottom: 15,
                fontStyle: 'italic',
                fontFamily: 'Poppins',
                fontWeight: 300,
                lineHeight: '22px',
                paddingRight: 31,
                textAlign: 'justify'
              }}>
                Urus Advisory's investigation was able to provide our client with valuable information and insights into the potential illegal sales of our products in the Middle East. Their comprehensive approach, including discreet source inquiries, allowed us to identify potential leads on where the KD-kits could have originated. Their findings were instrumental in our ability to take swift action to protect our brand and reputation.
              </p>
              <b 
                  className={styles.visible_xs}
                  style={{
                    fontSize: '14px',
                    letterSpacing: '-0.4px',
                    fontWeight: 600,
                  }}><i> Client – Global vehicle manufacturer</i></b>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <PreviewImage /> */}
      <OfficeLocation />

      <CSForm />
      <Footer isMinimized={false} />
    </>
  )
}

export default Contact

import React from "react"
import { Col, Container, Row } from "react-grid-system";
import HeaderSpacing from "../shared/components/HeaderSpace/HeaderSpace";

const SiteMap = () => {

  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    margin: '50px 0',
    width: '100%',
    display: 'block'

  };

  const headingStyle = {
    color: '#fff',
    marginBottom: 40
  };

  const ulStyle = {
    listStyleType: 'none',
    padding: '0',
    margin: '0',
  };

  const liStyle = {
    padding: 0,
    marginBottom: 30,
  };

  const linkStyle = {
    color: '#14346F',
    textDecoration: 'none',
    marginBottom: 15,
    display: 'block'
  };

  const linkHoverStyle = {
    textDecoration: 'underline',
  };

  const metadataStyle = {
    marginLeft: '0px',
    color: '#777',
    fontSize: '11pt'
  };

  const subListStyle = {
    marginBottom: 5
  }

  return <div style={{
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }}>
    <div style={{
      background: '#14346F',
    }}>
      <Container>
        <h1 style={headingStyle}>Sitemap</h1>
      </Container>
    </div>
    <div>
      <Container>
        <div style={containerStyle}>
          <ul style={ulStyle}>
            <li style={liStyle}>
              <a href="https://dev.urusadvisory.com" style={linkStyle}>https://dev.urusadvisory.com</a>
              <ul style={metadataStyle}>
                <li style={subListStyle}>Last Modified: 2024-01-23</li>
                <li style={subListStyle}>Change Frequency: always</li>
                <li style={subListStyle}>Priority: 0.5</li>
              </ul>
            </li>
            <li style={liStyle}>
              <a href="https://dev.urusadvisory.com/#/" style={linkStyle}>https://dev.urusadvisory.com/#/</a>
              <ul style={metadataStyle}>
                <li style={subListStyle}>Last Modified: 2024-01-23</li>
                <li style={subListStyle}>Change Frequency: always</li>
                <li style={subListStyle}>Priority: 0.5</li>
              </ul>
            </li>
            <li style={liStyle}>
              <a href="https://dev.urusadvisory.com/#/service" style={linkStyle}>https://dev.urusadvisory.com/#/service</a>
              <ul style={metadataStyle}>
                <li style={subListStyle}>Last Modified: 2024-01-23</li>
                <li style={subListStyle}>Change Frequency: always</li>
                <li style={subListStyle}>Priority: 0.5</li>
              </ul>
            </li>
            <li style={liStyle}>
              <a href="https://dev.urusadvisory.com/#/about-us" style={linkStyle}>https://dev.urusadvisory.com/#/about-us</a>
              <ul style={metadataStyle}>
                <li style={subListStyle}>Last Modified: 2024-01-23</li>
                <li style={subListStyle}>Change Frequency: always</li>
                <li style={subListStyle}>Priority: 0.5</li>
              </ul>
            </li>
            <li style={liStyle}>
              <a href="https://dev.urusadvisory.com/#/contact" style={linkStyle}>https://dev.urusadvisory.com/#/contact</a>
              <ul style={metadataStyle}>
                <li style={subListStyle}>Last Modified: 2024-01-23</li>
                <li style={subListStyle}>Change Frequency: always</li>
                <li style={subListStyle}>Priority: 0.5</li>
              </ul>
            </li>
          </ul>
        </div>
      </Container>
    </div>
    <div style={{
      padding: 20,
      background: '#14346F',
    }}>
    </div>
  </div>
}

export default SiteMap

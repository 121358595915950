import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import styles from './styles.module.scss'

const GetInTouch = () => {
  return (
    <div className={styles.get_in_touch}>
      <Container style={{
        maxWidth: 1100
      }}>
        <Row>
          <Col xs={12}>
            <h3>Get in touch</h3>
            <p>Ready to make informed decisions about your third-party partnerships? Let our due diligence consulting experts provide the critical insights you need. Contact us today to get started.</p>
            <button>contact us</button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default GetInTouch


import React, { useEffect } from 'react';

import Footer from "../Home/components/Footer/Footer"
// import Header from '../../shared/components/Header/Header';
import Header from '../../shared/components/Header_old/Header';

import HeaderSpacing from '../../shared/components/HeaderSpace/HeaderSpace';
import Form from "../Home/components/Form/Form"
import Bubble from '../Service/components/Bubble/Bubble';
import SpecialBlog from '../Service/components/SpecialBlog/SpecialBlogNoPhoto';
import TextBubble from '../../shared/components/TextBubble/TextBubble';
import useScrollToPage from '../../utils/useScrollToPageRef';
import TextBubbleSubPages from '../../shared/components/TextBubble/TextBubbleSubPages';

const SupplyChainsSanctionsCompliance = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      // @ts-ignore
      behavior: 'instant'
    });

  }, [])


  return (
    <div>
      <HeaderSpacing />
      <Header
        headerText={`Supply Chains and Sanctions Compliance`}
        subHeaderText='Providing tools to enhance and support risk-based supply chain compliance programs'
        headerStyles={{
        }}
        headerSubTitleStyle={{
        }}
        headerWrapperStyles={{
          background: '#191736'
        }}
        buttonText={'Request a service '}
        buttonStyles={{
          fontWeight: 700
        }}
      />
      <TextBubbleSubPages
        leftSection={<>
          <h3 style={{
            textAlign: 'left'
          }}>
            Supply Chains and Sanctions Compliance
          </h3>
          <p>
            In today's geopolitical landscape, marked by turmoil and an unprecedented number of international sanctions, the risks associated with engaging in international commerce and investment have reached new heights. This environment places additional stress on compliance systems, particularly for clients operating across multiple high-risk jurisdictions. To safeguard against illicit actors and mitigate sanctions risk, it is crucial for our clients to understand the vulnerabilities within their supply chains. Urus Advisory offers a comprehensive suite of integrity services designed to identify risks and weaknesses in supply chains and to implement strategies to reduce these risks effectively.
          </p>
          <p>
            Our approach ensures that our clients can navigate the complexities of international trade and investment with confidence, fortified by robust compliance practices and a deeper understanding of their supply chain dynamics.
          </p>
        </>}
        rightTitle={'The services available for our clients are:'}
        direction={'column'}
        bubbleItems={[
          'Supply chain investigations',
          'Sanctions risk analysis',
          'Sanctions risk management',
          'Screening of supply chain partners',
          'Weak points analysis',
        ]}
      />
      <Bubble
        direction={"column"}
        styleType='points'
        title='Why choose Urus Advisory for Supply Chains and Sanctions Compliance?'
        bubbleItems={[{
          title: 'First-hand experience',
          description: 'Urus Advisory’s investigations team has a solid track record in complex cross-border projects',
        },
        {
          title: 'Unique insights',
          description: 'With the network of local experts and industry insiders, we offer expertise in identifying vulnerabilities that are often overlooked.',
        },
        {
          title: 'Comprehensive approach',
          description: 'Our expertise spans across due diligence and integrity checks throughout Eurasia, enabling us to deliver relevant, in-depth data on supply chain participants to further minimise risks and enhance operational integrity.',
        },
        ]} />
      <SpecialBlog
        quote="Staying informed is crucial for pre-empting and resolving potential challenges. Thorough knowledge of your product's journey, especially when it traverses supply chains across diverse regions with opaque practices, is essential. This understanding not only mitigates risks but also ensures compliance and operational integrity in an increasingly complex global market."
        author='Sergei G - Head of Supply Chains and Sanctions Compliance'
      />
      <Form serviceOfInterest='Supply Chains and Sanctions Compliance' />
      <Footer isMinimized={false} />
    </div>
  );
};

export default SupplyChainsSanctionsCompliance;

import React from "react"
import { Col, Container, Row } from "react-grid-system"
import { Link } from "react-router-dom"
import styles from './styles.module.scss'

const TextBubbleSubPages = (props: {
  leftSection: JSX.Element
  isBlue?: boolean
  rightTitle?: string
  isHrEnable?: boolean
  bubblesRight?: boolean
  direction: 'row' | 'column'
  bubbleItems: string[]
  styleType?: 'bubble' | 'points'
}) => {

  const { styleType = 'bubble', isHrEnable = true, isBlue = false, bubblesRight = true } = props
  return <div className={styles.sub_pages}>
    {isHrEnable && <hr style={{
      border: '0.5px solid rgb(158 158 158 / 9%)',
      margin: 0
    }} />}
    <div style={{
      background: !isBlue ? '#fff' : '#14346F',
    }} className={styles.section}>
      <Container style={{
        maxWidth: 1156.7
      }}

      >
        <Row style={{
          alignItems: 'stretch',
        }}>
          <Col lg={6}
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '0 !important',
            }}
          >
            <div
              style={{
                color: !isBlue ? 'black' : '#fff'
              }}
              className={`${styles.left_section} ${styles[styleType + '_section']} ${styles[(props.direction ?? 'row') + '_left_section']}`}>
              <div className={styles.generic_content}>{props.leftSection}</div>
            </div>
          </Col>
          <Col lg={6} style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <div className={`${styles.right_section} ${styles[styleType + '_right_section']} ${styles[(props.direction ?? 'row') + '_right_section']} ${styles.generic}`}>
              <h4 className={`${styles[(props.direction ?? 'row') + '_title']} ${styles[styleType + '_title']}`}>
                <span>{props.rightTitle}</span>
              </h4>
              <div className={`${styles.bubble_container} ${styles[props.direction ?? 'row']} ${styles[styleType + '_container']}`}>
                {props.bubbleItems.map(item => {
                  return <div className={styles.row}><div className={`${styles.item} ${styles[styleType]}`}>
                    {
                      <span
                        style={{
                          ...(isBlue && styleType == "bubble" && { background: '#EC5922' })
                        }}
                      >
                        {item}
                      </span>

                    }
                  </div></div>
                })}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>

  </div>
}

export default TextBubbleSubPages

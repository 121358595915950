// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles-module__block--DdAHp {
  padding: 130px 0 13px;
  color: #222344;
  background-color: #fff;
}
.styles-module__block--DdAHp h3 {
  margin-top: 0px;
  font-size: 40px;
  line-height: 50px;
  font-family: "Lora";
  font-weight: 400;
  margin-bottom: 45px;
  color: #191736;
}
.styles-module__block--DdAHp .styles-module__content--Qizcr {
  max-width: 1078px;
  font-size: 13pt;
  line-height: 25pt;
  margin: 0 auto;
  /* text-align: justify; */
  display: flex;
  flex-direction: column;
  letter-spacing: 0px;
  line-height: 32px;
}
.styles-module__block--DdAHp .styles-module__content--Qizcr p {
  margin-bottom: 40px;
  font-weight: 300;
  font-family: Poppins;
  font-size: 16px;
}
.styles-module__block--DdAHp button {
  background-color: #EC5922;
  text-transform: uppercase;
  color: #fff;
  font-family: "Inter";
  border: none;
  font-size: 14pt;
  padding: 15px 45px;
  margin-top: 50px;
}
@media screen and (max-width: 767px) {
  .styles-module__block--DdAHp {
    padding: 45px 0 5px;
  }
  .styles-module__block--DdAHp h3 {
    font-size: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/components/HeaderText/styles.module.scss","webpack://./src/shared/_colors.scss"],"names":[],"mappings":"AAEA;EACE,qBAAA;EACA,cAAA;EACA,sBAAA;AADF;AAEE;EACE,eAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,cCTS;ADSb;AAGE;EACE,iBAAA;EAEA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,yBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,iBAAA;AAFJ;AAKI;EACE,mBAAA;EACA,gBAAA;EACA,oBAAA;EACA,eAAA;AAHN;AAOE;EACE,yBCtCY;EDuCZ,yBAAA;EACA,WClCU;EDmCV,oBAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AALJ;AAQE;EA9CF;IA+CI,mBAAA;EALF;EAME;IACE,eAAA;EAJJ;AACF","sourcesContent":["@import '../../../../shared/colors';\n\n.block {\n  padding: 130px 0 13px;\n  color: #222344;\n  background-color: #fff;\n  h3 {\n    margin-top: 0px;\n    font-size: 40px;\n    line-height: 50px;\n    font-family: 'Lora';\n    font-weight: 400;\n    margin-bottom: 45px;\n    color: $blue-color;\n  }\n\n  .content {\n    max-width: 1078px;\n    // font-size: 16pt;\n    font-size: 13pt;\n    line-height: 25pt;\n    margin: 0 auto;\n    /* text-align: justify; */\n    display: flex;\n    flex-direction: column;\n    letter-spacing: 0.0px;\n    line-height: 32px;\n    // margin-bottom: 30px;\n\n    p {\n      margin-bottom: 40px;\n      font-weight: 300;\n      font-family: Poppins;\n      font-size: 16px;\n    }\n  }\n\n  button {\n    background-color: $primary-color;\n    text-transform: uppercase;\n    color: $white-color;\n    font-family: \"Inter\";\n    border: none;\n    font-size: 14pt;\n    padding: 15px 45px;\n    margin-top: 50px;\n  }\n\n  @media screen and (max-width: 767px) {\n    padding: 45px 0 5px; \n    h3 {\n      font-size: 12px;\n    }   \n  }\n}\n","$primary-color: #EC5922;\n$primary-btn-color: #FF783F;\n$base-color: #0E0E1B;\n$nav-color: #333366;\n$blue-color: #191736;\n$primary-bg-color: #191736;\n$white-color: #fff;\n$light-gray: #D7D7D7;\n$bg-light-blue: #484581;\n$secondary-bg: #EEE0DA;\n$primary-bg: #FFF5F1\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `styles-module__block--DdAHp`,
	"content": `styles-module__content--Qizcr`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  max-width: none !important;
}

html {
  scroll-behavior: smooth;
}

.page-enter {
  opacity: 0;
  transform: translateY(500px);
}

.page-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: transform 500ms, opacity 500ms;
}

.page-exit {
  opacity: 1;
  transform: translateY(0);
}

.page-exit-active {
  opacity: 0;
  transform: translateY(-500px);
  transition: transform 500ms, opacity 500ms;
}

*,
a,
p,
span {
  font-family: "Poppins";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lora";
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  max-height: 100%;
  overflow-y: auto;
}

.ReactModal__Content {
  bottom: 50px;
  max-height: calc(100vh - 50px);
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

@media screen and (max-width: 767px) {
  .react-parallax {
    min-height: 700px;
    max-height: calc(100vh - 100px);
  }
}

.react-parallax-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/reset-bootstrap.scss"],"names":[],"mappings":"AAEA;EACE,0BAAA;AADF;;AAIA;EACE,uBAAA;AADF;;AAIA;EACE,UAAA;EACA,4BAAA;AADF;;AAIA;EACE,UAAA;EACA,wBAAA;EACA,0CAAA;AADF;;AAIA;EACE,UAAA;EACA,wBAAA;AADF;;AAIA;EACE,UAAA;EACA,6BAAA;EACA,0CAAA;AADF;;AAIA;;;;EAIE,sBAAA;AADF;;AAIA;;;;;;EAME,mBAAA;AADF;;AAIA;EACE,UAAA;EACA,qCAAA;EACA,gBAAA;EACA,gBAAA;AADF;;AAIA;EACE,YAAA;EACA,8BAAA;AADF;;AAIA;EACE,UAAA;AADF;;AAIA;EACE,UAAA;AADF;;AAKE;EADF;IAEI,iBAAA;IACA,+BAAA;EADF;AACF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AAAF","sourcesContent":["@import './shared/_colors.scss';\n\n.container {\n  max-width: none !important;\n}\n\nhtml {\n  scroll-behavior: smooth;\n}\n\n.page-enter {\n  opacity: 0;\n  transform: translateY(500px);\n}\n\n.page-enter-active {\n  opacity: 1;\n  transform: translateY(0);\n  transition: transform 500ms, opacity 500ms;\n}\n\n.page-exit {\n  opacity: 1;\n  transform: translateY(0);\n}\n\n.page-exit-active {\n  opacity: 0;\n  transform: translateY(-500px);\n  transition: transform 500ms, opacity 500ms;\n}\n\n*,\na,\np,\nspan {\n  font-family: 'Poppins';\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  font-family: \"Lora\";\n}\n\n.ReactModal__Overlay {\n  opacity: 0;\n  transition: opacity 200ms ease-in-out;\n  max-height: calc(100%);\n  overflow-y: auto;\n}\n\n.ReactModal__Content {\n  bottom: 50px;\n  max-height: calc(100vh - 50px);\n}\n\n.ReactModal__Overlay--after-open {\n  opacity: 1;\n}\n\n.ReactModal__Overlay--before-close {\n  opacity: 0;\n}\n\n.react-parallax {\n  @media screen and (max-width: 767px) {\n    min-height: 700px;\n    max-height: calc(100vh - 100px);\n  }\n}\n.react-parallax-content{\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles-module__explore--Asp5J {
  padding: 120px 0 150px;
}
.styles-module__explore--Asp5J .styles-module__title--nTUrL {
  display: flex;
  gap: 12px;
  font-family: "Open Sans";
  font-weight: 700;
  margin-bottom: 54px;
  align-items: center;
}
.styles-module__explore--Asp5J .styles-module__title--nTUrL h3 {
  margin: 0;
}
.styles-module__explore--Asp5J .styles-module__title--nTUrL img {
  position: relative;
  top: 4px;
  height: 25.4px;
}
.styles-module__explore--Asp5J .styles-module__item--Dm4H1 {
  max-width: 355px;
}
@media screen and (max-width: 675px) {
  .styles-module__explore--Asp5J .styles-module__item--Dm4H1 {
    margin-left: auto;
    margin-right: auto;
  }
}
.styles-module__explore--Asp5J .styles-module__item--Dm4H1 .styles-module__img_wrapper--c9O0n {
  max-width: 355px;
  max-height: 255px;
  margin-bottom: 33px;
}
.styles-module__explore--Asp5J .styles-module__item--Dm4H1 .styles-module__img_wrapper--c9O0n img {
  object-fit: cover;
  width: 100%;
}
.styles-module__explore--Asp5J .styles-module__item--Dm4H1 h5 {
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 12.8pt;
  font-weight: 700;
  font-family: "Open Sans";
}
.styles-module__explore--Asp5J .styles-module__item--Dm4H1 p {
  font-size: 11pt;
  line-height: 15pt;
}`, "",{"version":3,"sources":["webpack://./src/pages/CaseStudies/components/Explore/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;AACE;EACE,aAAA;EACA,SAAA;EACA,wBAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;AACJ;AAAI;EACE,SAAA;AAEN;AACI;EACE,kBAAA;EACA,QAAA;EACA,cAAA;AACN;AAGE;EACE,gBAAA;AADJ;AAEI;EAFF;IAGI,iBAAA;IACA,kBAAA;EACJ;AACF;AAAI;EACE,gBAAA;EACA,iBAAA;EACA,mBAAA;AAEN;AAAM;EACE,iBAAA;EACA,WAAA;AAER;AAEI;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,gBAAA;EACA,wBAAA;AAAN;AAGI;EACE,eAAA;EACA,iBAAA;AADN","sourcesContent":[".explore {\n  padding: 120px 0 150px;\n\n  .title {\n    display: flex;\n    gap: 12px;\n    font-family: 'Open Sans';\n    font-weight: 700;\n    margin-bottom: 54px;\n    align-items: center;\n    h3 {\n      margin: 0;\n    }\n\n    img {\n      position: relative;\n      top: 4px;\n      height: 25.4px;\n    }\n  }\n\n  .item {\n    max-width: 355px;\n    @media screen and (max-width: 675px) {\n      margin-left: auto;\n      margin-right: auto;\n    }\n    .img_wrapper {\n      max-width: 355px;\n      max-height: 255px;\n      margin-bottom: 33px;\n\n      img {\n        object-fit: cover;\n        width: 100%;\n      }\n    }\n\n    h5 {\n      margin-top: 0;\n      margin-bottom: 40px;\n      font-size: 12.8pt;\n      font-weight: 700;\n      font-family: 'Open Sans';\n    }\n\n    p {\n      font-size: 11pt;\n      line-height: 15pt;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"explore": `styles-module__explore--Asp5J`,
	"title": `styles-module__title--nTUrL`,
	"item": `styles-module__item--Dm4H1`,
	"img_wrapper": `styles-module__img_wrapper--c9O0n`
};
export default ___CSS_LOADER_EXPORT___;

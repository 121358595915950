// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles-module__input--AFKsR {
  margin: 20px 0;
  width: 100%;
  height: 52px;
  border: none;
  padding: 0 20px;
  box-sizing: border-box;
  border: none;
  font-family: Poppins;
  transition: all 0.3s;
}
.styles-module__input--AFKsR:focus {
  border: 1px solid #E3D0C9;
}
.styles-module__input--AFKsR:hover {
  transform: scale(1.02);
}
.styles-module__input--AFKsR::placeholder {
  font-family: Poppins;
  color: #8C8C8C;
  font-weight: 300;
}

.styles-module__dropdown--rDivL {
  display: flex;
  flex-direction: column;
  background-color: white;
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 80px;
}
.styles-module__dropdown--rDivL > span {
  padding: 15px 20px;
  cursor: pointer;
  transition: all 0.4s;
  font-size: 13px;
  color: #000000;
}
.styles-module__dropdown--rDivL > span:hover {
  background-color: #f8f6f6;
}

::-webkit-input-placeholder {
  font-style: italic;
}

:-moz-placeholder {
  font-style: italic;
}

::-moz-placeholder {
  font-style: italic;
}

:-ms-input-placeholder {
  font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/Input/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,sBAAA;EACA,YAAA;EACA,oBAAA;EACA,oBAAA;AACF;AACE;EACE,yBAAA;AACJ;AAEE;EACE,sBAAA;AAAJ;AAEE;EACE,oBAAA;EACA,cAAA;EACA,gBAAA;AAAJ;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;EACA,SAAA;AADF;AAGE;EACE,kBAAA;EACA,eAAA;EACA,oBAAA;EACA,eAAA;EACA,cAAA;AADJ;AAGI;EACE,yBAAA;AADN;;AAMA;EACE,kBAAA;AAHF;;AAMA;EACE,kBAAA;AAHF;;AAMA;EACE,kBAAA;AAHF;;AAMA;EACE,kBAAA;AAHF","sourcesContent":[".input {\n  margin: 20px 0;\n  width: 100%;\n  height: 52px;\n  border: none;\n  padding: 0 20px;\n  box-sizing: border-box;\n  border: none;\n  font-family: Poppins;\n  transition: all 0.3s;\n\n  &:focus {\n    border: 1px solid #E3D0C9;\n  }\n\n  &:hover {\n    transform: scale(1.02);\n  }\n  &::placeholder {\n    font-family: Poppins;\n    color: #8C8C8C;\n    font-weight: 300;\n  }\n}\n\n.dropdown {\n  display: flex;\n  flex-direction: column;\n  background-color: #ffff;\n  position: absolute;\n  z-index: 1;\n  width: 100%;\n  top: 80px;\n\n  &>span {\n    padding: 15px 20px;\n    cursor: pointer;\n    transition: all 0.4s;\n    font-size: 13px;\n    color: #000000;\n\n    &:hover {\n      background-color: #f8f6f6;\n    }\n  }\n}\n\n::-webkit-input-placeholder {\n  font-style: italic;\n}\n\n:-moz-placeholder {\n  font-style: italic;\n}\n\n::-moz-placeholder {\n  font-style: italic;\n}\n\n:-ms-input-placeholder {\n  font-style: italic;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `styles-module__input--AFKsR`,
	"dropdown": `styles-module__dropdown--rDivL`
};
export default ___CSS_LOADER_EXPORT___;

import React from "react"
import { Col, Container, Row } from "react-grid-system"
import { Link } from "react-router-dom"
import styles from './styles.module.scss'

const TextBubbleNew2 = (props: {
  leftSection: JSX.Element
  isBlue?: boolean
  rightTitle?: string
  isHrEnable?: boolean
  bubblesRight?: boolean
  direction: 'row' | 'column'
  bubbleItems: { title: string; link: string }[]
  styleType?: 'bubble' | 'points'
}) => {

  const { styleType = 'bubble', isHrEnable = true, isBlue = false, bubblesRight = true } = props
  return <div className={styles.left_bubbles}>
    {/* {isHrEnable && <hr style={{
      border: '0.5px solid rgb(158 158 158 / 9%)',
      margin: 0
    }} />} */}
    <div style={{
      background: !isBlue ? '#fff' : '#14346F',
      // color: !isBlue ? '#0000' : '#fff'
    }} className={styles.section}>
      {/* <Container fluid>
        <Col lg={6}></Col>
        <Col lg={6}></Col>
      </Container> */}
      <Container style={{
        maxWidth: 1156.7
      }}

      >
        <Row style={{
          alignItems: 'stretch',
        }}>
          <Col lg={6}
            className={styles.right_section_wrapper}
            style={{
              display: 'flex',
              alignItems: 'center'
            }}>
            <div className={`${styles.right_section} ${styles[styleType + '_right_section']} ${styles[(props.direction ?? 'row') + '_right_section']}`}>
              <h4 className={`${styles[(props.direction ?? 'row') + '_title']} ${styles[styleType + '_title']}`}
                style={{
                }}
              ><span>{props.rightTitle}</span></h4>
              <div className={`${styles.bubble_container} ${styles[props.direction ?? 'row']} ${styles[styleType + '_container']}`}>
                {props.bubbleItems.map(item => {
                  return <div>{<div className={`${styles.item} ${styles[styleType]}`}>
                    {
                      <Link to={item.link} className={styles.link}>
                        <img src={require('../../../../public/images/arrow_right_alt.svg')} />
                        <span
                          style={{
                            ...(isBlue && styleType == "bubble" && { background: '#EC5922' })
                          }}
                        >
                          {item.title}
                        </span>
                      </Link>
                    }
                  </div>}</div>
                })}
              </div>
            </div>
          </Col>
          <Col lg={6}
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '0 !important',
            }}
          >
            <div
              style={{
                color: !isBlue ? 'black' : '#fff'
              }}
              className={`${styles.left_section} ${styles[styleType + '_section']} ${styles[(props.direction ?? 'row') + '_left_section']}`}>
              {/* {props.leftSection} */}
              <h3 style={{
                // color: '#fff'
              }}>
                We highlight risks and help safeguard opportunities
              </h3>
              <br />
              <p>
                The Urus Advisory team has grown from its early days in 2016, covering the FSU and Central Asia, adding ever wider geographical expertise and sectoral know-how.
              </p>
              <p>
                With <b>over 20 in-house expert analysts and a vast network of local partners,</b> our firm is known for its insightful risk intelligence services.
              </p>
              <p>
                Our team is equipped to provide your business with comprehensive analysis and advisory support tailored to address a variety of situations.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>

  </div>
}

export default TextBubbleNew2

import React from "react"
import { Container, Row, Col } from "react-grid-system"
import styles from './styles.module.scss'
const Explore = () => {
  return <div className={styles.explore}>
    <Container>
      <Row>
        <Col>
          <div className={styles.title}>
            <h3>Explore more Case Studies</h3>
            <img src={require('../../../../../public/images/three_arrows.svg')} alt="" />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <div className={styles.item}>
            <div className={styles.img_wrapper}>
              <img src={require('../../../../../public/images/explore_1.jpg')} alt="" />
            </div>
            <h5>Sanctions Violation</h5>
            <p>There is just enough space here for several lines of text. Make sure you get your message across clearly yet concisely.</p>
          </div>
        </Col>
        <Col sm={4}>
          <div className={styles.item}>
            <div className={styles.img_wrapper}>
              <img src={require('../../../../../public/images/explore_2.jpg')} alt="" />
            </div>
            <h5>Asset recovery arm of a financial macro-
              regulator in a CIS country</h5>
            <p>There is just enough space here for several lines of text. Make sure you get your message across clearly yet concisely.</p>
          </div>
        </Col>
        <Col sm={4}>
          <div className={styles.item}>
            <div className={styles.img_wrapper}>
              <img src={require('../../../../../public/images/explore_3.jpg')} alt="" />
            </div>
            <h5>Building customer loyalty and retention in volatile times</h5>
            <p>There is just enough space here for several lines of text. Make sure you get your message across clearly yet concisely.</p>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
}

export default Explore

import React, { useEffect } from 'react';
import Footer from "../Home/components/Footer/Footer"
import Header from '../../shared/components/Header_old/Header';
import HeaderSpacing from '../../shared/components/HeaderSpace/HeaderSpace';
import Form from "../Home/components/Form/Form"
import Bubble from '../Service/components/Bubble/Bubble';
import SpecialBlog from '../Service/components/SpecialBlog/SpecialBlog';
import TextBubble from '../../shared/components/TextBubble/TextBubble';
import useScrollToPage from '../../utils/useScrollToPageRef';
import TextBubbleSubPages from '../../shared/components/TextBubble/TextBubbleSubPages';

const AssetTracking = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      // @ts-ignore
      behavior: 'instant'
    });
  }, [])

  return (
    <div>
      <HeaderSpacing />
      <Header
        headerText={`Asset Tracing, Litigation \n& Dispute Support`}
        subHeaderText='Adaptable and persistent approach to recovering value'
        headerStyles={{
        }}
        headerSubTitleStyle={{
        }}
        headerWrapperStyles={{
          background: '#191736'
        }}
        buttonText={'Request a service '}
        buttonStyles={{
          fontWeight: 700
        }}
      />
      <TextBubbleSubPages
        leftSection={<>
          <h3 style={{
            textAlign: 'left'
          }}>
            Asset Tracing, Litigation & Dispute Support
          </h3>
          <p>
            Commercial and investment partnerships break down all too often. So many situations see one side exposed to potential losses – and often they are significant. In such cases, meticulous and timely tracing of assets can be critical for recovering value. It can be the difference between saving a business or not.
          </p>
          <p>
            Our Global Asset Tracing services empower clients to uncover the full spectrum of a target’s holdings, including legal entities, real estate, financial assets securities, digital and crypto; right through to intellectual property, and luxury items. The right information may be essential for pre-litigation preparation, and post-judgment enforcement.
          </p>
          <p>
            Many of our commissions emanate from our ‘home patch’ of Eurasia, but take us across all continents. We harbour a strong record of success and strive to keep it that way. We know that intelligence and information in the context of asset tracing must be forensically sourced, complete – and actionable.
          </p>
          <p style={{
          }}>
            Parties can dissipate holdings into an enormous range of classes. Trusts and foundations, offshore holdings and nominee structures these days can appear almost ‘traditional.’ Increasingly inventive methods are constantly being created and staying on top is part of our raison d'être. From modern digital assets to complex securities and even privately-stored artefacts – we keep ahead.
          </p>
          <p>
            Urus works with legal teams, parties to disputes, and other strategists as a specialist contractor with a wealth of skill and experience to draw upon.
          </p>
        </>}
        rightTitle={'The services available for our clients are:'}
        direction={'column'}
        bubbleItems={[
          'Global and national-level asset identification',
          'Contextual and behavioural research',
          'Expert evidence for legal proceedings – attachment, discovery',
          'Financial analysis',
          'Multiple asset classes'
        ]}
      />
      <Bubble
        direction={"column"}
        styleType='points'
        title='Urus Advisory’s asset tracing know-how'
        bubbleItems={[{
          title: 'Deep understanding of complex jurisdictions',
          description: 'Our vast volume of casework experience has taken us to some of the most complex jurisdictions in the world. We have traced the assets and activities of some of the most sophisticated actors deploying labyrinthine techniques. Understanding such areas and approaches instinctively puts us ahead of others.',
        },
        {
          title: 'Experience in multiple scenarios',
          description: 'We help on cases of asset recovery, complex litigation and arbitration – and assessing funding. Our work applies to international insolvency, for parties seeking interim relief through disclosure and freezing orders – and for leverage in mediation or negotiations. Moreover, we often help with enforcing judgments and awards in Common Law jurisdictions, as well as Civil. We also have experience in helping major investigations, including PCPs.',
        },
        {
          title: 'Blended techniques',
          description: 'Urus Advisory draws together an array of tools – from deep-web research, confidential and sensitive enquiries, primary witness conversations, e-discovery management; to careful forensic study and monitoring of asset flows. Our multilingual team and breadth of legal, financial, investigative and geostrategic training makes our asset-tracing output unique.',
        },
        {
          title: 'Knowing what is needed, and when',
          description: 'We don’t just retrieve information; we make sure it is usable. Retrieving the right proof of ownership, compiling intelligence, and admitting it in an affidavit or expert opinion – and chasing down filings and archived documentation promptly… this is all part of our trade. We work with leading experts either end of the delivery spectrum, and regularly contribute decisive material, on time.',
        },
        ]} />
      <SpecialBlog
        quote={'Who is behind this – how have they behaved, and where does it stem from? We constantly need to ask such questions. Taking the most inquisitive approach possible, thinking through a strategy – and then deploying the best resources is the way we can come up trumps for our clients.'}
        author={'Alexander Teddy'}
        title={'Managing Director'}

      />
      <Form serviceOfInterest='Asset Tracing' />
      <Footer isMinimized={false} />
    </div>
  );
};

export default AssetTracking;
